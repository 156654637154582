import React from "react";
import {Container} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";

const ErrorPage = (props) => {

  console.log(useLocation())

  return (
    <>
      <PageTransition>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        <Container fluid className="error-page">
          <img src={IMAGES.ErrorPageBg} alt="" className="error-page__bg"/>
          <Container fluid="xl" className="error-page__container">
            <div className="d-flex w-100 ">
              <img
                src={IMAGES.Logo404}
                alt="carlisle"
                width="270"
                height="43"
                className="error-page__logo"
              />
            </div>

            <h2 className="error-page__title">
              Нет такой страницы!
            </h2>
            <p className="error-page__code">
              404
            </p>
            <p className="error-page__info">
              Увы! Произошла ошибка.
              <br/>
              Попробуйте вернуться на сайт.
            </p>
            <Link to="/" className="button button--white error-page__button-white">
            <span>
              вернуться на сайт
            </span>
            </Link>
          </Container>
        </Container>
      </PageTransition>
    </>
  );
};

export default ErrorPage;
