import {combineReducers, createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "@redux-devtools/extension";
import thunk from 'redux-thunk'
import modelsReducer from "./reducers/models";
import {createBrowserHistory} from "history";
import productsReducer from "./reducers/products";
import cartReducer from "./reducers/cart";
import makingOrderReducer from "./reducers/making_order";
import filterReducer from "./reducers/filters";
import modalsReducer from "./reducers/modals";
import {configureStore} from '@reduxjs/toolkit'

export const history = createBrowserHistory();

const combineReducer = combineReducers({
  products: productsReducer,
  models: modelsReducer,
  cart: cartReducer,
  making_order: makingOrderReducer,
  filters: filterReducer,
  modals: modalsReducer
});

const store = createStore(combineReducer,composeWithDevTools(applyMiddleware(thunk)))
// const store = configureStore({
//   middleware: undefined, preloadedState: undefined,
//   reducer: combineReducer,
//   devTools: true
// })
export type RootState = ReturnType<typeof combineReducer>

export default store;
