import React, {FC, memo, useEffect, useState} from 'react';
import './product-card.sass'
import {IMAGES} from "../../constants/images";
import {IProduct} from "../../types/types";
import {openCart, setProductCart} from "../../redux/actions/cart";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {Price} from "../../utils/helpers";
import {imageUrl} from "../../constants";
import {reloadProductAfterAddCart, setProduct} from "../../redux/actions/products";

const ProductCard = ({product}) => {

  const {name, id, artnumber, price, new_price, model, count} = product as IProduct

  // const [loading, setLoading] = useState()

  const dispatch = useDispatch()

  const handleAddProductCart = (data: IProduct) => {
    // console.log(data)
    dispatch(reloadProductAfterAddCart(data))
    dispatch(setProductCart(data))
    dispatch(openCart())
  }

  // console.log(product);

  return (
    // <Container>
    //   <Row>
    //     <Col xs={4} md={3}>
    <div className="product-card is--showed">

      <Link to={`/product/${id}`}  className="product-card__image-block">
        <img src={`${imageUrl}${model.picture}`} alt="" width="143" height="200"/>
      </Link>

      <div className="product-card__info-block">
        <Link to={`/product/${id}`} className="product-card__name">
          {name}
        </Link>
        <div className="product-card__articul">
          <span className="articul">Артикул</span>
          <span className="articul-number">{artnumber}</span>
        </div>
        <div className="product-card__prices">

          {new_price
            ?
            <div className="product-card__price">
              <span className="price">{Price(new_price)}</span>
              <span className="rub">{" "}₽</span>
            </div>
            :
            <div className="product-card__price">
              <span className="price">{Price(price)}</span>
              <span className="rub">{" "}₽</span>
            </div>
          }

          {/*{new_price*/}
          {/*&&*/}
          {/*<div className="product-card__old-price">*/}
          {/*  <span className="price">{Price(price)}</span>*/}
          {/*  <span className="rub">{" "}₽</span>*/}
          {/*</div>*/}
          {/*}*/}

        </div>
      </div>

      <div className="product-card__button-block">
        {count
          ?
          <Link
            to="/cart"
            className="button button--blue product-card__button-blue">
            <span>Товар в корзине</span>
          </Link>
          :
          <button
            type="button"
            onClick={e => handleAddProductCart(product)}
            className="button button--blue product-card__button-blue">
            {count
              ?
              <span>Товар в корзине</span>
              :
              <span>В корзину</span>
            }
          </button>
        }
      </div>

    </div>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default ProductCard;
