import React, {useEffect} from 'react';

const UseDebounce = (callback, ms, deps) => {
  useEffect(() => {
    if(deps === "") return
    let timer = null;
    timer = setTimeout(() => callback(), ms);
    return () => {
      clearTimeout(timer);
    };
  }, [deps]);
};

export default UseDebounce;
