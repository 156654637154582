import React, {memo} from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Col, Row} from "react-bootstrap";
import {colorSkeleton} from "../../constants";

const ModelSkeleton = () => {
  return (
    <>

      <Col lg={12} md={12} sm={12} xs={12}>
        <SkeletonTheme
          baseColor={colorSkeleton['baseColor']}
          highlightColor={colorSkeleton['highlightColor']}
        >
          <Skeleton count={1} height={275} />
        </SkeletonTheme>
      </Col>

    </>
  );
};

export default memo(ModelSkeleton);
