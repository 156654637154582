import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import SliderAbout from "../slider-models/SliderAbout";
import {Link} from "react-router-dom";
import {Autoplay} from "swiper";
import {IMAGES} from "../../constants/images";

const AboutBlockAboutPage = () => {
  return (
    <Container fluid className="main-about">
      <Container>
        <Row>
          <Col xl={6} md={6}>
            {/*<AboutGrid/>*/}

            <div className="slider-models image-about">
              <div className="slider-about__slide">
                <img src={IMAGES.SlideAbout4} alt="" />
                <div className="slider-about__info">
                  Буквы на боковой стенке меняются, но все остальное остается прежним
                </div>
              </div>
            </div>


          </Col>
          <Col xl={6} md={6}>
            <div className="main-about__body main-about__body--about-page">
              <h2 className="main-about__title">О производителе</h2>
              <h3 className="main-about__sub-title">
                CARLSTAR GROUP
              </h3>
              <div className="main-about__info" style={{marginBottom: "0px"}}>
                В 2023 году <strong>CARLSTAR GROUP</strong> представила эволюцию бренда CARLISLE направленную на то,
                чтобы обеспечить
                компании постоянное превосходство и рост в будущем.
                <div className="main-about__pre" />
                В течение следующих нескольких месяцев Carlstar Group будет переводить продукты под брендом Carlisle на
                бренд Carlstar.
                Продукция с новым логотипом начнет поступать на наши склады начиная с сентября 2023 года.
                <div className="main-about__pre" />
                Представители CARLSTAR заявляют, что смена названия никак не повлияла на качество изготавливаемой
                продукции
                и на политику компании в целом: <strong>
                <i>
                  "Буквы на боковой стенке меняются, но все остальное остается прежним",
                </i>
              </strong> - сообщают они.
                <div className="main-about__pre" />
                Подробная информация на официальном сайте производителя —
                <a
                  target="_blank"
                  href="https://carlstartires.eu/2023/07/05/the-carlstar-group-announces-brand-transition/">
                  CARLSTAR GROUP
                </a>
              </div>
            </div>
          </Col>
          <p className="main-about__info"
             style={
               {marginBottom: "0", marginTop: 25}
             }
          >
            <strong>CARLSTAR GROUP</strong> является мировым лидером и производителем оригинального оборудования
            специальных шин и дисков для вторичного рынка. Carlstar Group работает с компаниями по всему континенту,
            имеет широкую и стабильную дистрибьюторскую сеть
            на мировом рынке шин.
            <div className="main-about__pre" />
            Компания предлагает широчайший ассортимент продуктов и решений для сельского хозяйства, строительства,
            перевозки грузов. Благодаря высоким показателям качества покрышки данного производителя в минимальной
            степени подвержены изломам отдельных их частей
            при воздействии негативных факторов условий работы спецтехники.
            <div className="main-about__pre" />
            В Carlstar Group работает более <strong>3 400</strong> сотрудников на <strong>17</strong> предприятиях,
            расположенных в четырех странах.
            Компания создала культуру воплощения замечательных идей в передовые продукты с наследием превосходства в
            области инноваций, качества и обслуживания клиентов.
            <div className="main-about__pre" />
            Шины бренда <strong>Carlisle</strong> являются основой, с которой компания начинала практически во всех сегментах.
            Специализированные шины <strong>Carlisle</strong> имеют широкий ассортимент продукции для газонов и садов,
            сельского хозяйства, строительства и промышленного сегмента. Торговая марка хорошо известна среди ведущих
            производителей и продавцов в индустрии шин, а бренд <strong>Carlisle</strong>, давно признанный лидером в отрасли, предлагает
            лучшее в своем классе качество и передовые технологии продукции.
          </p>
        </Row>
      </Container>
    </Container>
  );
};

export default AboutBlockAboutPage;
