import MainPage from "./pages/MainPage";
import Test from "./pages/Test";
import WarrantyPage from "./pages/WarrantyPage";
import AboutCompanyPage from "./pages/AboutCompanyPage";
import CartPage from "./pages/CartPage";
import ContactsPage from "./pages/ContactsPage";
import ProductPage from "./pages/ProductPage";
import ModelPage from "./pages/ModelPage";
import DeliveryPage from "./pages/DeliveryPage";
import TechnologiesPage from "./pages/TechnologiesPage";
import CatalogPage from "./pages/CatalogPage";
import ModelsPage from "./pages/ModelsPage";
import ErrorPage from "./pages/ErrorPage";
import {Navigate} from "react-router-dom";
import PoliticsPage from "./pages/PoliticsPage";
import InvitePage from "./pages/InvitePage";

export const routes = [
  {title: 'Главная', path: "/", element: <MainPage title="Главная"/>},
  // {title: "Гарантия", path: "/about/warranty", element: <WarrantyPage title="Гарантия"/>},
  {
    title: "О компании",
    path: "/about",
    element: <AboutCompanyPage title="О компании"/>,
  },
  {title: "Корзина", path: "/cart", element: <CartPage title="Корзина"/>},
  {
    title: "Продукты",
    path: "/product/:product_id",
    element: <ProductPage title=""/>,
  },
  {title: "Контакты", path: "/contacts/contacts-company", element: <ContactsPage title="Контакты"/>},
  {title: "Контакты", path: "/contacts", element: <Navigate to="/contacts/contacts-company"/>},
  {
    title: "Доставка и оплата",
    path: "/delivery",
    element: <DeliveryPage title="Доставка и оплата"/>,
  },
  {
    title: "Тестовая страница",
    path: "/model/:model_id",
    element: <ModelPage title=""/>,
  },
  {title: "Модели", path: "/models", element: <ModelsPage title="Модели"/>},
  // {
  //   title: "Шины для грузовой техники",
  //   path: "/models/trucks",
  //   element: <ModelsPage title="Шины для грузовой техники"/>,
  // },
  // {
  //   title: "Шины для сельхозтехники",
  //   path: "/models/agricultural",
  //   element: <ModelsPage title="Шины для сельхозтехники"/>,
  // },
  // {
  //   title: "Шины для спецтехники",
  //   path: "/models/special",
  //   element: <ModelsPage title="Шины для спецтехники"/>,
  // },
  // {
  //   title: "Технологии",
  //   path: "/about/technologies",
  //   element: <TechnologiesPage title="Технологии"/>,
  // },
  {title: "Каталог", path: "/catalog", element: <CatalogPage title="Каталог"/>},
  {title: null, path: "*", element: <ErrorPage title="Страница не найдена"/>},
  // {title: "О компании", path: "/about", element: <Navigate to="/about/about-company"/>},
  {
    title: "Политика конфиденциальности",
    path: "/politika-konfidentsialnosti",
    element: <PoliticsPage title="Политика конфиденциальности"/>
  },
  {title: "как стать дилером", path: "/contacts/invite", element: <InvitePage title="Как стать дилером"/>},
  // {title: "Тестовая страница", path: "/test", element: <Test/>},
];
