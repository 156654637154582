import React, {memo} from 'react';
import {Container} from "react-bootstrap";
import CartItem from "../cart-item/CartItem";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {IProduct} from "../../types/types";
import {Declination, Price} from "../../utils/helpers";
import {IMAGES} from "../../constants/images";
import {Link} from "react-router-dom";
import {removeAllProductCart, selectAllProduct} from "../../redux/actions/cart";
import {resetMakingOrder} from "../../redux/actions/making_order";


const CartProductList = () => {

  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch()
  const handleSelectAllProduct = () => {

    {
      cart.select_all
        ?
        dispatch(selectAllProduct(false))
        :
        dispatch(selectAllProduct(true))

    }
  }

  const handleDeleteProductAllCart = () => {
    dispatch(removeAllProductCart())
    dispatch(resetMakingOrder())
  }

  return (
    <Container fluid className="cart-area">
      <Container className="cart-area__container">


        <div className="cart-block__title-block cart-block__title-block--new">
          <h2 className="cart-block__title">
            В корзине
          </h2>
          <div className="count-product">
                <span className="count-product__count">
                  {cart?.total_count}
                  {" "}
                </span>
            {Declination(cart?.total_count, ['товар', 'товара', 'товаров'])}
          </div>
        </div>

        <div className="cart-product-list-block">
          {cart.products.length !== 0
            ?
            <>
              {/*<div className="select-product">*/}
              {/*  <div className="form-check form-check-select-product">*/}
              {/*    <input className="form-check-input"*/}
              {/*           type="checkbox"*/}
              {/*           onChange={()=>handleSelectAllProduct()}*/}
              {/*           checked={!!cart.select_all}/>*/}
              {/*  </div>*/}
              {/*  <div className="select-product__buttons">*/}
              {/*    {!cart.select_all*/}
              {/*      ?*/}
              {/*      <button type="button" className="button select-product__button all"*/}
              {/*              onClick={() => handleSelectAllProduct()}>*/}
              {/*        Выбрать все*/}
              {/*      </button>*/}
              {/*      :*/}
              {/*      <button type="button" className="button select-product__button all"*/}
              {/*              onClick={() => handleSelectAllProduct()}>*/}
              {/*        Отменить выделение*/}
              {/*      </button>*/}
              {/*    }*/}
              {/*    {cart.select_all*/}
              {/*    &&*/}
              {/*    <button type="button" className="button select-product__button remove"*/}
              {/*            onClick={() => handleDeleteProductAllCart()}>*/}
              {/*      Удалить выбранное*/}
              {/*    </button>*/}
              {/*    }*/}

              {/*  </div>*/}
              {/*</div>*/}

              <div className="cart-list ">
                {cart?.products.map((product: IProduct, index: number) => {
                  return (
                    <CartItem
                      key={product.id}
                      product={product}
                      // key={product.id}
                      // name={product.name}
                      // artnumber={product.artnumber}
                      // brand={product.brand_id}
                      // price={product.new_price ? Price(product.new_price) : Price(product.price) }
                      // count={product.count}
                    />
                  )
                })}

              </div>
            </>
            :
            <Container fluid className="cart-area">
              <Container className="cart-area__container">
                <div className="cart-stub">
                  <div className="cart-stub__content">
                    <p className="cart-stub__text">
                      <span className="black">В корзине{" "}</span>
                      <span className="red">нет ни одного{" "}</span> <span className="black">товара</span>. Вы можете
                      подобрать интересующие вас товары в нашем каталоге товаров.
                    </p>
                    <div className="cart-stub__buttons d-flex justify-content-center">
                      <Link to="/catalog" className="button button--red cart-stub__button">
                        <span>
                          Подобрать товары
                        </span>
                      </Link>
                    </div>
                    {/*<div className="cart-stub__image-block d-flex justify-content-center">*/}
                    {/*  <img src={IMAGES.CartStub} alt="Пустая корзина"/>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </Container>
            </Container>
          }

        </div>
      </Container>
    </Container>
  );
};

export default memo(CartProductList);
