import {useState, useEffect, memo} from "react";
import "./breadcrumbs.sass";
import {Container} from "react-bootstrap";
import {routes} from "../../routeList";
import {useLocation, Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton} from "../../constants";
import {IProduct} from "../../types/types";
import {sections} from "../../constants"

const Breadcrumbs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState(null);
  const [prevPageTitle, setPrevPageTitle] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const location = useLocation();

  const product: IProduct = useSelector(
    (state: RootState) => state.products.product
  );

  const model = useSelector((state: RootState) => state.models.model);

  const fetchedPaths = ['product/', 'model/'];

  useEffect(() => {
    let needFetch = false;

    fetchedPaths.forEach((path) => {
      if (location.pathname.includes(path)) {
        needFetch = true;
        return;
      }
    });

    if (!needFetch && !(location.pathname.trim()).endsWith('catalog')) {
      routes.forEach((route) => {
        if (route.path === location.pathname) {
          setPageTitle(route.title);
          setPrevPageTitle(null);
          setPrevUrl(null);

          return;
        }
        setIsLoading(false);
      });
    } else if (!needFetch && location.pathname.trim().endsWith("catalog")) {
      let t = "Все шины";
      let section = 0;

      const params = location.search.trim().replace("?", "").split("&");

      params.forEach((param) => {
        const value = param.split("=");

        if (value[0] === "sections") {
          section = +value[1];
        }
      });

      // switch (section) {
      //   case 14:
      //     t = "Шины для сельхозтехники";
      //     break;
      //   case 18:
      //     t = "Шины для грузовой техники";
      //     break;
      //   case 27:
      //     t = "Шины для спецтехники";
      //     break;
      // }

      setPageTitle(t);
      setPrevPageTitle(null);
      setPrevUrl(null);
      setIsLoading(false);

      return;
    }

    if (product && product.id && isLoading) {
      // console.log("useEffect product", product);

      setPageTitle(product.name);

      const sectionId = product.section_id;
      const href = `/catalog?sections=${sectionId}`;
      let sectionName = 'Каталог';

      // Object.keys(sections).forEach(key => {
      //   if (sections[key].id === sectionId) {
      //     sectionName = sections[key].mainTitle;
      //   }
      // })

      setPrevPageTitle(sectionName);
      setPrevUrl(href);

      setIsLoading(false);

      return;
    }

    if (model && model.id && isLoading) {
      // console.log("useEffect model", model);

      setPageTitle(model.name);

      const sectionId = model.section_id;
      let href = '/models';
      let sectionName = "Модели";

      // Object.keys(sections).forEach((key) => {
      //   if (sections[key].id === sectionId) {
      //     sectionName = sections[key].mainTitle;
      //     href = `/models/${key}`;
      //   }
      // });

      setPrevPageTitle(sectionName);
      setPrevUrl(href);

      setIsLoading(false);

      return;
    }
  }, [location, product, model]);


  if (isLoading) {
    return (
      <Container fluid className="breadcrumbs-area">
        <Container className="breadcrumbs-area__container">
          <SkeletonTheme
            baseColor="#E2E5ED"
            highlightColor={colorSkeleton["highlightColor"]}
          >
            <Skeleton
              count={1}
              height={26}
            />
          </SkeletonTheme>
        </Container>
      </Container>
    );
  }

  return (
    <Container fluid className="breadcrumbs-area">
      <Container className="breadcrumbs-area__container">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" className="breadcrumbs__link">
              Главная
            </Link>
          </li>

          {prevPageTitle && (
            <li className="breadcrumbs__item">
              <Link to={prevUrl} className="breadcrumbs__link">
                {prevPageTitle}
              </Link>
            </li>
          )}

          <li className="breadcrumbs__item">
            <span className="breadcrumbs__link">{pageTitle}</span>
          </li>
        </ul>
      </Container>
    </Container>
  );
};

export default memo(Breadcrumbs);
