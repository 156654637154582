import React, {memo} from 'react';
import Filter from "../filter/Filter";
import {useDispatch, useSelector} from "react-redux";
import {IMAGES} from "../../constants/images";
import {toggleModalFilterMobile} from "../../redux/actions/modals";

const MobileFilter = () => {
  const modals = useSelector((state) => state.modals);

  const dispatch = useDispatch();

  const handleCloseFilterMobile = () => {
    dispatch(toggleModalFilterMobile(false))
  }

  return (
    <div className="mobile-filter-area d-lg-none d-block">
      <div className={"mobile-filter " + (modals.modal_filter_mobile ? "active" : "")}>
        <div className="mobile-filter__area">
          <img
            onClick={() => handleCloseFilterMobile()}
            className="mobile-filter__icon"
            src={IMAGES.CloseIcon} alt=""/>
          <Filter/>
        </div>

      </div>
    </div>
  );
};

export default memo(MobileFilter);
