import {useRef, useState, createRef, memo, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {MAIL, PHONE_LINK} from "../../constants";
import {IMAGES} from '../../constants/images'
import SearchIcon from '../../assets/icons/search.svg';
import OrderCall from "./OrderCall"
import {useDispatch, useSelector} from "react-redux";
import {Price} from "../../utils/helpers";
import CartProductWindow from "./cart-product-window/CartProductWindow";
import {toggleDropWindowCart} from "../../redux/actions/cart";
import api from "../../api";
import {createUrl} from "../../redux/actions/filters";
import Navigation from "./Navigation";
import {toggleMobileMenu, toggleModalCall, toggleModalCallMobile} from "../../redux/actions/modals";
import useClickOutside from "../../hooks/useClickOutside";
import {CSSTransition} from "react-transition-group";
import UseDebounce from '../../hooks/useDebounce';

const Search = ({isOpen, setIsOpen, type}) => {
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')

  const search_ref = useRef()

  const [result, setResult] = useState([])

  const navigate = useNavigate()


  const [linkRefs, setLinkRefs] = useState([])

  const [active, setActive] = useState(-1)


  const searchApi =()=>{
    const _url = `search=${search}&limit=1000`

    api.getProducts(_url).then(res => {
      setResult(res.products)
      let _refs = []
      res.products.forEach(item => {
        const myRefs = createRef();
        _refs.push(myRefs)
      })
      setLinkRefs(_refs)
      // setActive(res.products[0].id)
    }).catch(e => {
      console.log(e)
    })
  }


  UseDebounce(searchApi, 500, search)

  const handlerSearch = (search) => {
    setSearch(search)
    setActive(-1)
    if(search === ''){
      setResult([])
    }
  }

  const resetSearch = () => {
    setSearch('')
    setResult([])
    {
      type !== "mobile"
      &&
      setIsOpen(false)
    }
  }

  const Submit = (e) => {
    e.preventDefault()
    if (active !== -1) return
    resetSearch()
    dispatch({type: 'RESET_FILTER_VALUE'})
    const _data = {
      value: search,
      type: 'search'
    }
    // console.log('sibmit')
    navigate(`/catalog?search=${search}`)
    // navigate(`/delivery?search=${search}`)
    dispatch(createUrl(_data))
  }


  const keyDown = (e) => {
    e.persist()
    // console.log(e.keyCode)
    let index = active
    switch (e.keyCode) {
      case 40: {
        index += 1
        if (index > result.length - 1) return;
        setActive(index)
        if (index > 5) {
          linkRefs[index].current.focus()
        }
        // console.log(1)
        break;
      }
      case 38: {
        index -= 1
        if (active === -1 || index === -1) return;
        // console.log(index)
        setActive(index)
        linkRefs[index].current.focus()
        break;
      }
      case 13: {
        if (active === -1) return
        const _id = result?.filter((_, index) => index === active)[0].id
        navigate(`/product/${_id}`)
        resetSearch()
        break;
      }
    }
  }

  useClickOutside(search_ref, () => resetSearch())

  return (
    <div
      ref={search_ref}
      className={"search-line-area " + (isOpen ? "search-line-area--open" : "")}>
      <form
        className="search-line" onSubmit={Submit}>
        <div className="search-line__content">
          <input type="text"
                autoFocus
                onKeyDown={keyDown}
                placeholder="Я ищу..."
                className="form-control" value={search}
                onChange={(e) => handlerSearch(e.target.value)}/>
          <button
            className="button button-search d-xl-flex"
            type="submit">
            <img src={IMAGES.SearchIcon} alt="Поиск"/>
          </button>
        </div>
      </form>
      <div className={"search-drop-area " + (result?.length > 0 ? "d-block" : "d-none")}>
        <ul className="search-result">


          {result?.map((item, index) => {
            return (
              <Link
                onKeyDown={keyDown}
                ref={linkRefs[index]}
                // type='button'
                onMouseEnter={e => setActive(index)}
                tabIndex={1}
                key={item.id}
                className={"search-result__link " + (index === active ? 'focus' : '')}
                to={`/product/${item.id}`}
                onClick={() => resetSearch()}
              >
                {item.name}
              </Link>

            )
          })}
        </ul>
      </div>
    </div>
  )
}

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const header = useRef()

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  const modals = useSelector((state) => state.modals)

  const handleToggleDropWindowCart = () => {
    dispatch(toggleDropWindowCart(!cart.drop_window_cart))
  }

  const handleToggleMobileMenu = () => {
    dispatch(toggleMobileMenu(!modals.mobile_menu))
  }

  const toggleOpen = (toggle) => {
    dispatch(toggleModalCall(toggle));
  }
  const target = useRef(null);

  const target_mob = useRef(null);

  useClickOutside(target, () => dispatch(toggleModalCall(false)))

  useClickOutside(target_mob, () => dispatch(toggleModalCallMobile(false)))
  // console.log(cart.total_count)
  return (
    <>
      {/*<CartProductWindow />*/}
      {/*<OrderCall/>*/}
      <div className="header" ref={header} id="header">
        <div className="header__line-up">
          <div className="header__line-content">

            <p className="header__text">
              Официальный дилер шин Сarlisle в России
            </p>
            <div className="header__contacts header-contacts">
              <div className="header-contacts">

                <div
                  className="header-contacts__order-call">

                  {/*{modals.modalCall*/}
                  {/*&&*/}

                  <CSSTransition

                    in={!!modals.modalCall}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                  >
                    <div className="modal-order-call" ref={target}>
                      <OrderCall/>
                    </div>
                  </CSSTransition>
                  {/*}*/}

                  <button
                    type="button"
                    className="order-call-btn"
                    onClick={() => toggleOpen(!modals.modalCall)}>
                    Заказать звонок
                  </button>

                </div>
                <a href={`tel:${PHONE_LINK}`} className="header-contacts__mail header-contacts__phone">
                  +7 495 <strong>740-82-00</strong>
                </a>
                <a href={`mailto:${MAIL}`} className="header-contacts__mail">
                  {MAIL}
                </a>

              </div>
            </div>
          </div>
        </div>

        <div className="header__main header-main d-lg-flex d-none">
          <div className="header-main__content">
            <Link to="/" className="header-main__logo">
              <img src={IMAGES.Logo} alt="Carlisle" width="250" height="41"/>
            </Link>

            <Navigation/>

            <div className="d-flex" style={{marginLeft: "auto"}}>
              <div className="button-search-area d-xl-flex ">

                <Search type="pc" isOpen={isOpen} setIsOpen={setIsOpen}/>

                <button
                  className="button button-search"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <img src={SearchIcon} alt="Поиск"/>
                </button>
              </div>

              <div className="button-cart-area">
                <button
                  onClick={() => handleToggleDropWindowCart()}
                  className="button header-main__button button-cart"
                >
                  <img
                    src={IMAGES.CartIcon}
                    alt=""
                    className="button-cart__icon"
                  />
                  <span className="button-cart__text">Корзина</span>
                  <div className="button-cart__total-price">
                    <span className="button-cart__price">
                      {cart?.total_price && Price(cart?.total_price)}

                      {/*<div className="spinner-border" role="status">*/}
                      {/*  <span className="sr-only"/>*/}
                      {/*</div>*/}
                    </span>
                    <span className="button-cart__rub"> ₽</span>
                  </div>
                </button>
                <CartProductWindow/>
              </div>
            </div>
          </div>
        </div>

        <div className="header-main-mobile d-lg-none d-block">
          <div className="header-main-mobile__content">
            <Link to="/" className="header-main-mobile__logo-area">
              <img src={IMAGES.MobileHeaderLogo} alt="Carlisle"/>
            </Link>

            <div className="d-flex align-items-center">

              <a href={`tel:${PHONE_LINK}`} className="header-contacts__mail header-contacts__phone">
                +7 495 <strong>740-82-00</strong>
              </a>

              <div className="button-mobile-call--area">
                <button
                  type="button"
                  className="button button-mobile-call"
                  onClick={() => dispatch({type: 'TOGGLE_MODAL_CALL_MOBILE', payload: true})}>
                  <img src={IMAGES.MobileCall} alt="Заказать звонок"/>
                </button>

                <CSSTransition
                  in={!!modals.modalCallMobile}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit>
                  <div className="modal-order-call" ref={target_mob}>
                    <OrderCall/>
                  </div>
                </CSSTransition>

              </div>

              <Link className="button button-mobile-cart" to="/cart">
                <img
                  src={IMAGES.CartMobileIcon}
                  alt=""
                  className="button-cart__icon"
                />
                <div className="button-cart__total-price">
                  <span className="button-cart__price">
                    {cart?.total_price && Price(cart?.total_price)}
                  </span>
                  <span className="button-cart__rub"> ₽</span>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="header-footer-mobile d-lg-none d-block">
          <div className="header-footer-mobile__content">
            <button
              type="button"
              onClick={() => handleToggleMobileMenu()}
              className="button button-mobile-menu"
            >
              <span/>
              <span/>
              <span/>
            </button>
            <div className="header-footer-mobile-search">
              <Search isOpen={isOpen} setIsOpen={setIsOpen} type="mobile"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default memo(Header);
