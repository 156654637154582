import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import api from "../api/index";
import {Container, Row, Col} from "react-bootstrap";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import ModelCard from "../components/model-card/ModelCard";
import {useDispatch, useSelector} from "react-redux";
import {getModelsAction, resetModel} from "../redux/actions/models";
import ModelCardSkeleton from "../components/model-card/ModelCardSkeleton";
import {sections} from "../constants";
import SliderModels from "../components/slider-models/SliderModels";
import PaginationModels from "../components/pagination/pagination_models";
import queryString from "query-string";
import {Helmet} from "react-helmet";
import ModelBanner from "../components/model-banner/ModelBanner";

const ModelsPage = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);

  const dispatch = useDispatch();

  const models = useSelector((state) => state.models);

  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const path = location.pathname.split("/");

  const section = sections[path[path.length - 1]];

  const init = async (url) => {
    const res = !section?.id ? await api.getModels(url) : await api.getModelsBySection(section?.id, url);
    dispatch(getModelsAction(res));
  };

  const _page = parsed.page ? parsed.page : page;
  const _limit = parsed.limit ? parsed.limit : limit;
  const _url = `?offset=${_page * _limit - _limit}&limit=${_limit}`;

  useEffect(() => {

    setPage(_page);
    setLimit(_limit);

    init(_url);
    return () => {
      setPage(1);
      setLimit(8);
      dispatch(resetModel());
    };
  }, [location]);

  useEffect(() => {
    setPage(1);
    setLimit(8);
    init("");
  }, [section]);

  const handleLimitChange = (page, limit) => {
    init(`?offset=${page}&limit=${limit}`);
  };

  return (

    <section className="cart-page">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
      </Helmet>

      <ModelBanner
        section={section}
      />

      <Breadcrumbs/>

      <Container fluid className="cart-area cart-area-new cart-area-bg">
        <Container className="cart-area__container cart-area-new__container">
          <div className="cart-block">
            <div className="cart-block__title-block flex-wrap model-page-title-block">
              <h2 className="cart-block__title">
                {typeof section?.title === "string" ? section?.title : "Все модели"}
              </h2>
              <div className="count-product"><span className="count-product__count">
                {models?.pagination?.total} </span>товаров
              </div>
              <div className="class-hz" >
                <div className="d-flex justify-content-end flex-wrap position-relative">
                  <div className="catalog-view-type-sort catalog-limit ">
                    <span>Показывать по:</span>
                    <Link
                      onClick={() => handleLimitChange(1, 8)}
                      title={`Показать ${limit} товара`}
                      to={`${location.pathname}?page=${1}&limit=${8}`}
                      className={"button catalog-limit__item " + (+limit === 8 ? "catalog-limit__item--active" : "")}>
                      8
                    </Link>
                    <Link
                      onClick={() => handleLimitChange(1, 16)}
                      title={`Показать ${limit} товаров`}
                      to={`${location.pathname}?page=${1}&limit=${16}`}
                      className={"button catalog-limit__item " + (+limit === 16 ? "catalog-limit__item--active" : "")}>
                      16
                    </Link>
                    <Link
                      onClick={() => handleLimitChange(1, 32)}
                      title={`Показать ${limit} товара`}
                      to={`${location.pathname}?page=${1}&limit=${32}`}
                      className={"button catalog-limit__item " + (+limit === 32 ? "catalog-limit__item--active" : "")}>
                      32
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {
              models.loadingModels
                ?
                <Row>
                  <ModelCardSkeleton/>
                </Row>
                :
                <Row>
                  {models.models.length === 0
                    ?
                    <Col>
                      <div className="cart-stub p-0">
                        <p className="cart-stub__text text-center">
                          <span className="black">Модели не найдены</span>
                        </p>
                      </div>
                    </Col>

                    :
                    models.models.map((model, index) => {
                      return (
                        <Col
                          xl={3}
                          lg={4}
                          md={6}
                          sm={12}
                          xs={12}
                          key={index}
                          className="col-model">
                          <ModelCard model={model}/>
                        </Col>
                      );
                    })
                  }


                </Row>
            }
            <PaginationModels
              // url={url}
              // page={page}
              location={location}
              pagination={models.pagination}
            />

          </div>
        </Container>

      </Container>
      <Container fluid className="models-area">
        <Container>
          <div className="title-block">
            <h2 className="title">Популярные модели</h2>
            {/*<Link to="/models" className="title-block__link">*/}
            {/*  Смотреть все*/}
            {/*</Link>*/}
          </div>
          <SliderModels/>
        </Container>
      </Container>
    </section>

  );
};

export default ModelsPage;
