import {memo, useEffect, useState} from "react";
import "./mobile-left-menu.sass";
import {Accordion} from "react-bootstrap";
import {LINKS} from "../../constants/links";
import {Link, useLocation} from "react-router-dom";
import {IMAGES} from "../../constants/images";
import SubLinkIcon from "../../assets/icons/menu-sub-link.svg";
import {useDispatch, useSelector} from "react-redux";
import api from "../../api";
import {createUrl} from "../../redux/actions/filters";
import {toggleMobileMenu} from "../../redux/actions/modals";

const MobileLeftMenu = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const [sections, setSections] = useState([]);

  const dispatch = useDispatch();

  const modals = useSelector((state) => state.modals);

  const init = () => {
    api
      .getFilterLinkSections()
      .then((response) => {
        setSections(response.sections);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    init();
    return () => {
      setLoading(true);
    };
  }, []);

  const onSelectChange = (id) => {
    const _data = {
      value: id,
      filter_field: "sections",
      type: "select",
    };
    dispatch(createUrl(_data));
    dispatch(toggleMobileMenu(false));
  };

  const handleToggleMobileMenu = (id) => {
    dispatch(toggleMobileMenu(!modals.mobile_menu));
    if(id) {
      onSelectChange(id)
    }else{
      onSelectChange(null)
    }

  };

  const isActiveTop = (path) => {
    return (location.pathname.trim() + location.search.trim()).includes(
      path.trim()
    );
  };

  const isActiveSub = (path) => {
    return (location.pathname.trim() + location.search.trim()).endsWith(
      path.trim()
    );
  };

  const isActiveParams = (path, section) => {
    let finded = false;
    let hasSections = false;
    const hasPath = (
      location.pathname.trim() + location.search.trim()
    ).includes(path.trim());

    const params = location.search.trim().replace("?", "").split("&");

    params.forEach((param) => {
      const value = param.split("=");

      if (value[0] === "sections") {
        hasSections = true;
        if (+value[1] === section) {
          finded = true;
        }
      }
    });

    if (!finded && !hasSections && hasPath && section === 0) finded = true;

    return finded;
  };

  return (
    <div className="left-mobile-menu-area d-xl-none d-block">
      <div className="wrapper">
        <div
          id="sidebar"
          className={"sidebar " + (modals.mobile_menu ? "active" : "")}
        >
          <button
            onClick={() => handleToggleMobileMenu()}
            type="button"
            className="button p-0"
            id="dismiss">
            <img src={IMAGES.CloseWhiteIcon} alt="Закрыть" />
          </button>

          <div className="sidebar__logo-block">
            <img src={IMAGES.Logo} alt="" />
          </div>

          <Accordion className="mobile-nav">
            {LINKS.map((item, index) => {
              return (
                <Accordion.Item
                  eventKey={index}
                  className="mobile-nav__item"
                  key={index}
                >
                  {item.sublink ? (
                    <Accordion.Header className="mobile-nav__header">
                      <span
                        className={`position-relative mobile-nav__text${
                          isActiveTop(item.link)
                            ? " mobile-nav__text--active"
                            : ""
                        }`}
                      >
                        {item.name}
                        {item.sublink && (
                          <img
                            src={SubLinkIcon}
                            alt=""
                            className="header-main__icon-arrow"
                          />
                        )}
                      </span>
                    </Accordion.Header>
                  ) : (
                    <Link
                      onClick={() => handleToggleMobileMenu()}
                      to={item.link}
                        className={`position-relative mobile-nav__text${isActiveTop(item.link)
                          ? " mobile-nav__text--active"
                          : ""
                          }`}
                    >
                      {item.name}
                    </Link>
                  )}

                  {item.sublink && (
                    <Accordion.Body className="mobile-nav__body">
                      <div className="mobile-nav__body-item">
                        {item.sublink.map((it, ind) => {
                          return (
                            <Link
                              onClick={()=> it?.matchSection > 0
                                ? handleToggleMobileMenu(it?.matchSection?.toString())
                                : handleToggleMobileMenu()
                              }
                              key={ind}
                              to={it?.link}
                              className="header-main-sublinks__text mobile-nav__link"
                            >
                              {it.matchSection > -1 ? (
                                isActiveParams(
                                  it.link,
                                  it.matchSection
                                ) ? (
                                  <img
                                    className="header-main-sublinks__icon"
                                    src={IMAGES.MenuCheckIcon}
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )
                              ) : isActiveSub(it.link) ? (
                                <img
                                  className="header-main-sublinks__icon"
                                  src={IMAGES.MenuCheckIcon}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}{" "}
                              {it.name}
                            </Link>
                          );
                        })}
                      </div>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
      <div className="overlay" />
    </div>
  );
};

export default memo(MobileLeftMenu);
