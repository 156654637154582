import {
  CREATE_URL, LOADING_FILTER,
  SET_FILTERS, SET_PAGINATION,
  CHANGE_FILTER_TYPE, RESET_FILTERS
} from "../constants";

const initialState = {
  url: "",
  // url: "",
  count: 0,
  loading_filter: true,
  //то что пришло с сервера(модифицированно под select выпадашки)
  default_value_price_from: 0,
  default_value_price_to: 0,
  type: "S",
  select: {
    diametrs: [],
    widths: [],
    heights: [],
    // sections: [],
    index_size: []
  },
  checkbox: {
    "brands[]": []
  },
  range: {
    prices: {
      price_from: 0,
      price_to: 0
    }
  },
  //то что выбрали в фильтре, из этого формируем URL
  select_value: {
    diametrs: [],
    widths: [],
    index_size: [],
    heights: [],
    // sections: []
  },
  checkbox_value: {
    "brands[]": []
  },
  range_value: {
    prices: {
      price_from: 0,
      price_to: 0
    }
  },
  sort: "",
  pagination: {
    total: 0,
    limit: 0,
    offset: 0
  },
  search: null,
  count_filter: 0
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {

    case "SET_DEFAULT_PRICES": {
      return {
        ...state,
        default_value_price_from: action.payload.price_from,
        default_value_price_to: action.payload.price_to
      };
    }

    case LOADING_FILTER: {
      return {
        ...state,
        loading_filter: action.payload
      };
    }

    case SET_PAGINATION: {

      return {
        ...state,
        pagination: action.payload
      };
    }

    case SET_FILTERS: {

      let _select = {
        diametrs: [],
        widths: [],
        index_size: [],
        heights: [],
        // sections: []
      };

      let _checkbox = {
        "brands[]": []
      };

      let _range = {
        prices: {
          price_from: 0,
          price_to: 0
        }
      };

      Object.keys(action.payload).map((item, index) => {
        if (state.select[item]) {
          action.payload[item].map(filter => {
            const _option = {
              value: "",
              label: ""
            };
            if (typeof filter === "object") {
              _option.value = filter.id;
              _option.label = filter.name;
            } else {
              _option.value = filter.toString();
              _option.label = filter.toString();
            }
            // console.log(_option)
            if (_select[item]) _select[item].push(_option);
          });
        } else if (state.checkbox[item]) {
          _checkbox[item] = action.payload[item];
        }
        // else if (state.range[item]) {
        //   // console.log('action.payload-------', action.payload)
        //   _range[item] = action.payload[item]
        // }
      });
      // console.log('select',_select)
      // console.log(_range)
      return {
        ...state,
        select: _select,
        checkbox: _checkbox,
        // range: _range,
        // range_value: _range,
        count: action.payload.count
      };
    }

    case "PARSE_URL": {
      let _select_value = state.select_value;
      let _checkbox_value = state.checkbox_value;
      let _range_value = state.range_value;
      let _sort = state.sort;
      let _pagination = state.pagination;
      let _type = state.type;
      let _search = state.search;
      let _url = state.url;

      const {filter_field, value, type} = action.payload;

      if (type === "type") {
        _type = action.payload.value;
      }

      if (type === "sort") {
        if (value === null) {
          _sort = "";
        } else {
          _sort = action.payload.value;
          // _pagination.offset = 0
        }

      }
      if (type === "search") {
        // _pagination.offset = 0
        _search = action.payload.value;
      }

      if (type === "select") {
        _search = null;
        if (value === null) {
          _select_value[filter_field] = [];
          _range_value.prices = {
            price_from: state.range.prices.price_from,
            price_to: state.range.prices.price_to
          };
        }
        if (value !== null
          && _select_value[filter_field].indexOf(value) === -1) {
          _select_value[filter_field] = [];
          _select_value[filter_field].push(value);
          // _pagination.offset = 0
        }
      }

      if (type === "checkbox") {
        _pagination.offset = 0;
        // console.log(action.payload)
        if (value === null) {
          _checkbox_value[filter_field] = [];
        }
        if (value !== null && _checkbox_value[filter_field].indexOf(value) === -1) {
          _checkbox_value[filter_field].push(value);
          // _pagination.offset = 0
        } else {
          _checkbox_value[filter_field] = _checkbox_value[filter_field].filter(x => x !== value);
          // _pagination.offset = 0
        }
      }

      if (type === "range") {
        if (value === null) {
          _range_value[filter_field] = {
            price_from: state.default_value_price_from,
            price_to: state.default_value_price_to
          };
        } else {
          _range_value[filter_field] = {
            price_from: action.payload.value[0],
            price_to: action.payload.value[1]
          };
        }
      }
      if (type === "pagination") {
        // _pagination.offset = 0
        if (value === null) {
          _pagination = {};
        } else {
          _pagination[filter_field] = +action.payload.value;
        }
      }

      _url += `${filter_field}=${value}&`;

      // if(_url[_url.length-1] === "&") _url = _url.splice(0,-1)
      return {
        ...state,
        url: _url,
        select_value: _select_value,
        checkbox_value: _checkbox_value,
        range_value: _range_value,
        sort: _sort,
        pagination: _pagination,
        type: _type,
        search: _search
      };
    }

    case CREATE_URL: {

      let _select_value = state.select_value;
      let _checkbox_value = state.checkbox_value;
      let _range_value = state.range_value;
      let _sort = state.sort;
      let _pagination = state.pagination;
      let _type = state.type;
      let _url = "";
      let _search = state.search;
      const {filter_field, value, type} = action.payload;

      // console.log(filter_field, value, type)

      if (type === "type") {
        _search = null;
        _type = action.payload.value;
        _url += `type=${_type}&`;
      }

      if (type === "sort") {
        if (value === null) {
          _sort = "";
        } else {
          _sort = action.payload.value;
          // _pagination.offset = 0
        }

      }
      if (type === "search") {
        // _pagination.offset = 0
        _search = action.payload.value;

      }

      if (type === "select") {
        _pagination.offset = 0;
        _search = null;
        if (value === null) {
          _select_value[filter_field] = [];
          _range_value.prices = {
            price_from: state.range.prices.price_from,
            price_to: state.range.prices.price_to
          };
        }
        if (value !== null
          && _select_value[filter_field].indexOf(value) === -1) {
          _select_value[filter_field] = [];
          _select_value[filter_field].push(value);
          // _pagination.offset = 0
        }

      }

      if (type === "checkbox") {
        _search = null;
        _pagination.offset = 0;
        // console.log(action.payload)
        if (value === null) {
          _checkbox_value[filter_field] = [];
        }
        if (value !== null && _checkbox_value[filter_field].indexOf(value) === -1) {
          _checkbox_value[filter_field].push(value);
          // _pagination.offset = 0
        } else {
          _checkbox_value[filter_field] = _checkbox_value[filter_field].filter(x => x !== value);
          // _pagination.offset = 0
        }
      }

      if (type === "range") {
        _pagination.offset = 0;
        _search = null;
        if (value === null) {
          _range_value[filter_field] = {
            price_from: state.default_value_price_from,
            price_to: state.default_value_price_to
          };
        } else {
          _range_value[filter_field] = {
            price_from: action.payload.value[0],
            price_to: action.payload.value[1]
          };
        }
      }
      if (type === "pagination") {
        // _pagination.offset = 0
        if (value === null) {
          _pagination = {};
        } else {
          _pagination[filter_field] = +action.payload.value;
        }
      }

      if (_sort) _url += `sort=${_sort}&`;
      if (_search) _url += `search=${_search}&`;

      Object.keys(_select_value).forEach(type => {
        Object.keys(_select_value[type]).forEach(key => {
          _url += `${type}=${_select_value[type][key]}&`;
        });
      });

      Object.keys(_checkbox_value).forEach(type => {
        Object.keys(_checkbox_value[type]).forEach(key => {
          _url += `${type}=${_checkbox_value[type][key]}&`;
        });
      });

      Object.keys(_range_value).forEach(type => {
        if (_range_value[type].price_from !== 0 && _range_value[type].price_from !== state.default_value_price_from) {
          _url += `price_from=${_range_value[type].price_from}&`;
        }
      });

      Object.keys(_range_value).forEach(type => {
        if (_range_value[type].price_to !== 0 && _range_value[type].price_to !== state.default_value_price_to) {
          _url += `price_to=${_range_value[type].price_to}&`;
        }
      });

      if (_pagination.limit > 0) _url += `limit=${_pagination.limit}&`;

      if (_pagination.offset > 0) _url += `offset=${_pagination.offset}&`;


      // if(_url[_url.length-1] === "&") _url = _url.splice(0,-1)
      // if(_search){
      //
      // }

      // _url += `type=${_type}&`
      // console.log('_url', _url)

      return {
        ...state,
        url: _url,
        select_value: _select_value,
        checkbox_value: _checkbox_value,
        range_value: _range_value,
        sort: _sort,
        pagination: _pagination,
        type: _type,
        search: _search
      };
    }

    case "FILTER_COUNT": {

      return {
        ...state,
        count_filter: action.payload
      };
    }

    case CHANGE_FILTER_TYPE: {
      return {
        ...state,
        type: action.payload
      };
    }
    case "RESET_FILTER_VALUE": {

      return {
        ...state,
        select_value: {
          diametrs: [],
          widths: [],
          index_size: [],
          heights: [],
          // sections: []
        },
        checkbox_value: {
          "brands[]": []
        },
        range_value: {
          prices: {
            price_from: 0,
            price_to: 0
          }
        },
        // pagination: {
        //   total: 0,
        //   limit: 0,
        //   offset: 0
        // },
        url: "",
        sort: "",
        type: "S",
        search: "",
        count_filter: 0
      };
    }

    case RESET_FILTERS: {
      return initialState;

    }

    default:
      return state;
  }
};

export default filterReducer;
