import React, {memo} from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton} from "../../constants";
import FilterSelect from "./FilterSelect";
import FilterCheckbox from "./FilterCheckbox";
import FilterRange from "./FilterRange";
import {Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
  changeFilterType, createUrl,
  loadingFilter,
  setFilter,
} from "../../redux/actions/filters";
import api from "../../api";
import {toggleModalFilterMobile} from "../../redux/actions/modals";
import {useNavigate} from "react-router-dom";

const Filter = () => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters);

  const _url = filters.url;

  const handlerChangeFilterType = async (data) => {
    dispatch({type: "RESET_FILTER_VALUE"});
    dispatch(changeFilterType(data));
    const _data = {
      value: data,
      type: 'type'
    }
    dispatch(createUrl(_data))
    // await api
    //   .getFilter(_url)
    //   .then((res) => {
    //     dispatch(setFilter(res));
    //     dispatch(loadingFilter(false));
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });

  };

  const handleCloseFilterMobile = () => {
    dispatch(toggleModalFilterMobile(false))
  }

  const navigate = useNavigate();

  const handlerResetAll = () => {
    dispatch({type: "RESET_FILTER_VALUE"});
    // navigate('/catalog')
  };

  return (
    <>
      <div className="filter-type">
        <div className="filter-type__content">
          <button
            // to="/catalog?type=S"
            onClick={() => handlerChangeFilterType("S")}
            className={
              "button filter-type__button " +
              (filters.type === "S" ? " filter-type__button--active" : "")
            }
          >
            <span className="filter-type__button-text">По размеру</span>
          </button>
          <button
            // to="/catalog?type=P"
            onClick={() => handlerChangeFilterType("P")}
            className={
              "button filter-type__button " +
              (filters.type === "P" ? " filter-type__button--active" : "")
            }
          >
            <span className="filter-type__button-text">По параметрам</span>
          </button>
        </div>
      </div>

      {filters.loading_filter ? (
        <SkeletonTheme
          baseColor={colorSkeleton["baseColor"]}
          highlightColor={colorSkeleton["highlightColor"]}
        >
          <Skeleton count={5} height={120} style={{marginBottom: 5}} />
        </SkeletonTheme>
      ) : (
        <div className="catalog-filter-area">
          {Object.keys(filters.select).map((filter, index) => {
            return (
              <FilterSelect
                key={index}
                filter_field={filter}
                filter_data={filters.select[filter]}
              />
            );
          })}

          {/*{Object.keys(filters.checkbox).map((filter, index) => {*/}
          {/*  return (*/}
          {/*    <FilterCheckbox*/}
          {/*      key={index}*/}
          {/*      filter_field={filter}*/}
          {/*      filter_data={filters.checkbox[filter]}*/}
          {/*    />*/}
          {/*  );*/}
          {/*})}*/}

          {Object.keys(filters.range_value).map((filter, index) => {
            // console.log(filters.range_value)
            return (
              <FilterRange
                key={index}
                filter_field={filter}
                filter_data={filters.range_value[filter]}
              />
            );
          })}

          <button
            onClick={() => handlerResetAll()}
            className="button filter-reset"
          >
            <span>Сбросить все</span>
          </button>

          <div className="filter__content d-lg-none d-block">
            <button
              type="button"
              onClick={() => handleCloseFilterMobile()}
              className="button button--white catalog-filter-area__button">
              <span>Показать</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
