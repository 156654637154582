import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/index.sass';
import MainRouter from './MainRouter';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        {/*<h1>React render</h1>*/}
        <MainRouter/>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
