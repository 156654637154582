import {IMAGES} from "../../constants/images";
import React, {Fragment, memo} from "react";
import {toggleModalThanks} from "../../redux/actions/modals";
import {useDispatch} from "react-redux";
import "./thanks-modal.sass";

const ThanksModal = () => {
  const dispatch = useDispatch();

  return (
    <div className="thanks-modal">
      <div className="thanks-modal__header">
        <span className="thanks-modal__title">Благодарим за обращение</span>

        {/* <button
          className="button"
          type="button"
          onClick={() => dispatch(toggleModalThanks(false))}
        >
          <img src={IMAGES.CloseIcon} alt="" />
        </button> */}

      </div>

      <div className="thanks-modal__content">С вами скоро свяжутся</div>

      <div className="thanks-modal__footer">
        <div className="thanks-modal__buttons">
          <button
            onClick={() => dispatch(toggleModalThanks(false))}
            className="button button--blue product-card__button-blue"
          >
            <span>Ок</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(ThanksModal);
