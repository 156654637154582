import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {RootState} from "../redux/store";
import api from "../api/index";
import {getModelAction, resetModel} from "../redux/actions/models";
import {setProductByModelAction, resetProducts, loadingProducts} from "../redux/actions/products";
import {IMAGES} from "../constants/images";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import TableProduct from "../components/table-product/TableProduct";
import Model from "../components/model/Model";
import ModelSkeleton from "../components/model/ModelSkeleton";
import TableSkeleton from "../components/table-product/TableSkeleton";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {colorSkeleton, sections} from "../constants";
import {Helmet} from "react-helmet";
import ModelBanner from "../components/model-banner/ModelBanner";
import PageTransition from "../components/PageTransition";

const ModelPage = (props) => {

  const dispatch = useDispatch();
  const {model_id} = useParams();

  const models = useSelector((state: RootState) => state.models);
  const products = useSelector((state: RootState) => state.products);

  const init = async () => {
    const responseModel = await api.getModel(model_id);
    const responseProductModel = await api.getProductByModel(model_id);
    dispatch(getModelAction(responseModel));
    dispatch(setProductByModelAction(responseProductModel));
    dispatch(loadingProducts(false));
  };

  useEffect(() => {
    init();
    return () => {
      dispatch(resetModel());
      dispatch(resetProducts());
      dispatch(loadingProducts(true));
    };
  }, []);

  const generationSection = (str) => {
    let section = {};
    if (str === "Спецтехника") {
      section = sections["special"];
    }
    if (str === "Грузовая техника") {
      section = sections["trucks"];
    }
    if (str === "Сельхозтехника") {
      section = sections["agricultural"];
    }
    return section;
  };


  return (

      <section className="cart-page ">

        <Helmet>
          <meta charSet="utf-8"/>
          <title>{models?.model?.name || "Модель"}</title>
        </Helmet>
        {models.loadingModel
          ?
          <SkeletonTheme
            baseColor={colorSkeleton["baseColor"]}
            highlightColor={colorSkeleton["highlightColor"]}>
            <Skeleton count={1} height={190} style={{width: "100%"}}/>
          </SkeletonTheme>
          :
          <ModelBanner
            type="models"
            section={generationSection(models.model.section_name)}
          />
        }

        <Breadcrumbs/>

        <Container fluid className="cart-area cart-area-bg">
          <Container className="cart-area__container d-flex flex-column">
            <div className="cart-block order-1 model-block">
              {models.loadingModel
                ?
                <ModelSkeleton/>
                :
                <Model
                  name={models.model.name}
                  brand={models.model.brand.name}
                  image={models.model.picture}
                  section={models.model.section_name}
                />
              }

            </div>
            {/*<Container fluid className="contacts-delivery model-delivery order-lg-2 order-3">*/}
            {/*  <Container className="p-0">*/}
            {/*    <Row>*/}
            {/*      <Col xl={12}>*/}
            {/*        <p>*/}
            {/*          <img src={IMAGES.DoubleMoon} alt="Логотип"/>*/}
            {/*          Доставка вашего товара транспортными компаниями осуществляется*/}
            {/*          по территории России и в страны СНГ. Рассчет стоимости доставки*/}
            {/*          осуществляется у представителей транспортной компании или вашего*/}
            {/*          персонального менеджера.*/}
            {/*        </p>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  </Container>*/}
            {/*</Container>*/}

            <div className="order-lg-3 order-2">
              {
                products.loadingProducts
                  ?
                  <TableSkeleton/>
                  :
                  <TableProduct
                    products={products.products}
                  />
              }
            </div>


          </Container>

        </Container>
      </section>

  );
};

export default ModelPage;
