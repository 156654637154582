import {IMAGES} from "./images";

export const imageUrl = 'https://mtkrosberg.ru'

export const colorSkeleton = {
  baseColor: '#EEF0F5',
  highlightColor: '#fff',
}

export const MAIL = 'info@carlisle-tyres.ru'
// export const MAIL2 = 'info@starmaxx-tyres.ru'
// export const MAIL3 = 'info@starmaxx-tyres.ru'

export const PHONE = `+7 495 <strong>740-82-00</strong>`
export const PHONE_LINK = '84957408200'

export const PHONE2 = '+3 630 <strong>774 22 59</strong>'
export const PHONE_LINK2 = '36307742259'

export const PHONE3 = '90 312 309 30 30'
export const PHONE_LINK3 = '84957406260'

export const EQUIPMENT = {
  D: 'Диагональная',
  R: 'Радиальная'
}

export const sections = {
  agricultural: {
    id: 14,
    mainTitle: 'Шины для сельхозтехники',
    title: 'Сельхозтехника'
  },
  trucks: {
    id: 18,
    mainTitle: 'Шины для грузовой техники',
    title: 'Грузовая техника'
  },
  special: {
    id: 27,
    mainTitle: 'Шины для Спецтехники',
    title: 'Спецтехника'
  },
}

export const agricultural = [
  {
    id: 1,
    image: IMAGES.Technology1,
    title: 'Арамидный корд',
    text: 'При уборке урожая приходится сталкиваться со сложными ситуациями на поле, особенно с жесткими остатками культур. Вот почему завод используем технологию арамидных кордов, которая может обеспечить дополнительную защиту от проколов пожнивными остатками и ударов о землю по сравнению со стальными или тканевыми альтернативами. Арамид обладает в пять раз большей прочностью, чем сталь при эквивалентном весе, и большей устойчивостью к нагреванию и износу. Кроме того, уменьшение веса шины приводит к улучшенному сцеплению и топливосбережению на поле, а меньшее количество резиновых слоев снижает нагрев шин и выбросы CO2.'
  },
  {
    id: 2,
    image: IMAGES.Technology2,
    title: 'Шины с улучшенной гибкостью',
    text: 'Уплотнение почвы стало одной из основных проблем для фермеров, поскольку размер и вес новейших сельскохозяйственных полевых машин и оборудования продолжают расти.\n' +
      'Шины с улучшенной гибкостью (IF) разработаны таким образом, чтобы оставлять пятно контакта большей площади по сравнению с обычными шинами, что приводит к меньшему уплотнению почвы и минимальному повреждению корней сельскохозяйственных культур. При одинаковом уровне давления шины IF могут нести большую нагрузку, чем стандартные шины, а при одинаковой нагрузке шины IF могут работать с более низким уровнем давления. Следовательно, шины с улучшенной гибкостью не только повышают сцепление с дорогой, но и способствуют увеличению прибыли производителей, обеспечивая более высокую топливную экономичность.'
  },
  {
    id: 3,
    image: IMAGES.Technology3,
    title: 'PT TRAC',
    text: 'Технология PT Trac - это уникальный продукт, сочетающий в себе традиционные шины и передовые технологии Trac, обеспечивающий максимальную производительность при сбалансированной стоимости.  Эти интеллектуальные сельскохозяйственные шины обеспечивают относительно более плавный ход с максимальным сцеплением с дорогой, а также увеличивают срок службы протектора и топливосбережение. Данная технология также обеспечивает возможность самоочищения в результате большего изгиба боковины.\n' +
      'Вогнутые боковины предотвращают повреждение боковой стенки, что сокращает время простоя.\n' +
      'Оптимальная широкая площадь контакта, уменьшающая уплотнение почвы до 40%, нацелена на обеспечение защиты и развития почвенного покрова. Их высокие сцепные характеристики, бескомпромиссные ходовые качества, улучшенная совместимость с возможностью снижения расхода топлива стали легендарными.\n' +
      'Позвольте нашим высокотехнологичным шинам использовать мощность двигателя, что обеспечит исключительную эффективности их работы.\n'
  }
]
export const special = [
  {
    id: 1,
    image: IMAGES.Technology4,
    title: 'Однониточная бортовая проволока',
    text: 'Технология однониточной бортовой проволоки используется на внедорожных шинах и усиливает связь между шиной и ободом для более точного вождения.\n' +
      'Также улучшает боковую устойчивость при прохождении поворотов на высокой скорости и равномерность для более плавного вождения. Благодаря этой технологии изготовления шин повышается однородность и долговечность шины.\n'
  },
  {
    id: 2,
    image: IMAGES.Technology5,
    title: 'Цельнометаллокордный каркас',
    text: 'Шины имеют стальные слои в боковой стенке и под протектором.\n' +
      'Эта конструкция используется для обеспечения большей прочности в сложных условиях по сравнению с комбинированными шинами с радиальной структурой (с корпусом из тканевого корда). Цельнометаллокордные шины обеспечивают высокую грузоподъемность, низкое тепловыделение и исключительную способность к восстановлению.\n' +
      '\n'
  },
  {
    id: 3,
    image: IMAGES.Technology6,
    title: 'Состав протектора',
    text: 'Высококачественные составные смеси обеспечивают максимальную устойчивость к резанию, проникновению и разрыву горных пород.\n' +
      'В то время как устойчивые к порезам составы способствуют долгому сроку службы, шины из термостойких составов подходят для вождения на высоких скоростях.'
  },
  {
    id: 4,
    image: IMAGES.Technology7,
    title: 'Конструкция цельнолитных шин',
    text: 'Цельнолитная конструкция шин-это технология, которая обеспечивает длительный срок службы, максимальную устойчивость к повреждениям и минимальные затраты на техническое обслуживание вилочных погрузчиков, работающих на ровных поверхностях.\n' +
      'Цельнолитные шины гарантируют значительное повышение эффективности работы и общей прибыльности при снижении затрат на техническое обслуживание и увеличении срока службы за счет обеспечения высочайшего качества с нуля благодаря своей 4-слойной структуре.\n' +
      '\n'
  }
]
export const truck = [
  {
    id: 1,
    image: IMAGES.Technology8,
    title: 'Восстановление',
    text: 'Максимизация срока службы важна для экономической эффективности шины, а увеличение срока службы еще лучше. Мы стремимся внести свой вклад в прибыль пользователей наших шин, и именно поэтому мы уделяем большое внимание долговечности корпуса наших шин. Прочная конструкция каркаса является ключевым фактором, делающим наши радиальные шины для грузовиков и автобусов пригодными для многократного восстановления протектора, что, в свою очередь, значительно снижает расход топлива на километр. Помимо этих преимуществ, восстановленная шина означает меньшее использование природных ресурсов при одновременном выделении меньшего количества CO2, поскольку это уменьшает как потребность в новом сырье, так и химические результаты производственных процессов.\n' +
      '\n'
  },
  {
    id: 2,
    image: IMAGES.Technology9,
    title: 'Однониточная бортовая проволока',
    text: 'Технология однониточной бортовой проволоки усиливает связь между шиной и ободом для более точного вождения. Кроме того, эта технология улучшает боковую устойчивость при прохождении поворотов на высокой скорости и повышает однородность для более плавного вождения, одновременно способствуя общей долговечности шины.\n'
  },
  {
    id: 3,
    image: IMAGES.Technology10,
    title: 'Эффективность топлива',
    text: 'Компания использует оптимизированное сочетание инновационных топливосберегающих смесей, методов конструкции шин и уникального дизайна протектора для снижения сопротивления качению. Усилия по снижению расхода топлива не только помогают конечным пользователям проехать лишние мили с тем же количеством топлива, но и вносят свой вклад в природу, сокращая выбросы CO2.'
  },
]
