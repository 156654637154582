import React, {memo, useEffect, useState} from "react";
import {IMAGES} from "../../constants/images";
import {useDispatch, useSelector} from "react-redux";
import {FiltersFields} from "../../constants/filters";
import {createUrl, filterCount} from "../../redux/actions/filters";
import {AnimatePresence, motion} from "framer-motion";

const FiltersBubbles = () => {

  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters);
  const {diametrs, widths, index_size, heights, sections} = filters.select_value;
  const {prices} = filters.range_value;
  const brands = filters.checkbox_value["brands[]"];

  const count = () => {
    let _count = 0;
    Object.keys(filters.select_value).forEach((filter, index) => {
      if (filters.select_value[filter].length > 0) {
        _count += 1;
      }
    });
    if (brands.length > 0) {
      _count += 1;
    }

    Object.keys(filters.range_value).forEach((filter, index) => {
      if (
        (filters.range_value[filter].price_from !== filters.default_value_price_from
          ||
          filters.range_value[filter].price_to !== filters.default_value_price_to
        )
        &&
        (
          filters.range_value[filter].price_from > 0
          ||
          filters.range_value[filter].price_to > 0
        )
      ) {
        _count += 1;
      }
    });
    return _count;
  };

  useEffect(() => {
    count();
    dispatch(filterCount(count()));
    // console.log('effect')
  }, [diametrs, widths, index_size, heights, sections, brands.length, prices]);

  const handleResetUnitFilter = (filter_field, type) => {
    const _data = {
      value: null,
      filter_field: filter_field,
      type: type
    };
    dispatch(createUrl(_data));
    // navigate(`/catalog?${filters.url}`)
  };


  const AnimationSettings = {
    transition: {duration: 0.3},
    initial: {x: 20, opacity: 0},
    animate: {x: 0, opacity: 1},
    exit: {x: -20, opacity: 0}
  };

  return (
    <>
      {filters.count_filter > 0
        ?
        <div className="bubble-area">
          <div className="bubble-list">
            {Object.keys(filters.select_value).map((filter, index) => {
              if (filters.select_value[filter].length === 0) return;
              // console.log(index)
              // setCount(prev => prev + 1)
              const valueSelect = () => {
                if (filter === "sections") {
                  const _id = filters?.select_value?.sections[0];
                  const res = filters?.select?.sections.filter(item => item.value === +_id)[0];
                  return res?.label;
                } else {
                  return filters?.select_value[filter][0];
                }
              };
              let value = valueSelect();
              return (
                <motion.div
                  {...AnimationSettings}
                  className="bubble"
                  key={index}
                >
                  <span>{FiltersFields[filter]} {value}</span>
                  <button
                    type="button"
                    onClick={() => handleResetUnitFilter(filter, "select")}
                    className="button bubble__button"
                  >
                    <img src={IMAGES.FilterCloseBubble} alt="закрыть"/>
                  </button>
                </motion.div>

              );
            })}
            {Object.keys(filters.checkbox_value).map((filter, index) => {
              if (filters.checkbox_value[filter].length === 0) return;
              // setCount(prev => prev + 1)
              let value = filters.checkbox[filter].filter(item => item.id === +filters.checkbox_value[filter][0])[0];
              return (
                <motion.div
                  {...AnimationSettings}
                  className="bubble"
                  key={index}
                >
                  <span>{FiltersFields[filter]} {value?.name}</span>
                  <button
                    type="button"
                    onClick={() => handleResetUnitFilter(filter, "checkbox")}
                    className="button bubble__button">
                    <img src={IMAGES.FilterCloseBubble} alt="закрыть"/>
                  </button>
                </motion.div>
              );
            })}
            {Object.keys(filters.range_value).map((filter, index) => {
              if (
                (filters.range_value[filter].price_from !== filters.default_value_price_from
                  ||
                  filters.range_value[filter].price_to !== filters.default_value_price_to
                )
                &&
                (
                  filters.range_value[filter].price_from > 0
                  ||
                  filters.range_value[filter].price_to > 0
                )
              ) {
                return (
                  <motion.div
                    {...AnimationSettings}
                    className="bubble"
                    key={index}>
                    <span> По цене</span>
                    <button
                      type="button"
                      onClick={() => handleResetUnitFilter(filter, "range")}
                      className="button bubble__button">
                      <img src={IMAGES.FilterCloseBubble} alt="закрыть"/>
                    </button>
                  </motion.div>
                );
              }
            })}
          </div>
        </div>
        :
        ""
      }
    </>
  );
};

export default FiltersBubbles;
