import React from 'react';
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";
import {Col, Container} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import FormInvite from "../components/forms/form-invite/FormInvite";
import {Link} from "react-router-dom";
import FormCommunicationManager from "../components/forms/form-communication-manager/FormCommunicationManager";
import Fancybox from "../hooks/Fancybox";
import {imageUrl} from "../constants";
import InviteTyre1 from "../assets/images/invite-type-1.png";

const InvitePage = (props) => {
  return <PageTransition>
    <section className="delivery-page invite-page">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
      </Helmet>
      <Container fluid className="delivery-page-banner">
        <img
          src={IMAGES.InviteBg}
          className="main-banner__image"
          alt="Как стать дилером"
        />

        <Container className="delivery-page-banner__container">
          <div className="main-banner__title-block delivery-page-banner__title-block">
            <h1 className="main-banner__title delivery-page-banner__title text-center">
              как стать дилером
            </h1>
          </div>
        </Container>
      </Container>

      <Breadcrumbs/>

      <Container fluid className="delivery-page-payment">
        <Container>
          <div className="title-block delivery-page-payment__title-block mb-0">
            <h2>Стать дилером</h2>
          </div>
        </Container>
      </Container>

      <Container fluid className="invite-page-form-area" id="form-invite">
        <FormInvite/>
      </Container>

      <Container fliud className="invite-page-info">
        <Container>
          <div className="title-block delivery-page-payment__title-block">
            <h2>О производителе</h2>
          </div>
          <p className=" invite-page-text-mod">
            <span>Carlstar Group</span> является мировым лидером и производителем специальных шин вторичного рынка.
            <div style={{height: '10px'}}></div>
            Компания предлагает широчайший ассортимент продуктов и решений для сельского хозяйства и строительства.
            Широкий ассортимент решений предлагается под несколькими ведущими брендами, включая шины Carlisle®, шины
            ITP® и др.
          </p>
        </Container>
      </Container>

      <Container fluid className="invite-page-detail">
        <div className="invite-page-detail__image-block">

          <picture>
            <source media="(min-width: 1920px)" srcSet={IMAGES.InviteBg1Big}/>
            <img className="invite-page-detail__image"
                 src={IMAGES.InviteBg1}
                 alt="подробнее о компании"/>
          </picture>
        </div>

        <div className="invite-page-list-area">
          <Container>
            <div className="invite-page-detail__list">
              <div className="company-product-list">
                <div className="company-product-item">
                  <img src={IMAGES.InviteTyre1}
                       alt="Шины для квадро/мототехники"
                       className="company-product-item__image-tyre"
                       style={{top: '-205.5px'}}
                  />

                  <div className="company-product-item__icon-area">
                    <img src={IMAGES.Car1} alt="" className="company-product-item__icon"/>
                  </div>
                  <p className="company-product-item__text">
                    шины
                    <br/>для квадро/
                    <br/>мототехники
                  </p>
                </div>
                <div className="company-product-item">
                  <img src={IMAGES.InviteTyre2}
                       alt="Шины многоцелевого назначения"
                       className="company-product-item__image-tyre"
                       style={{top: '-210px'}}
                  />
                  <div className="company-product-item__icon-area">
                    <img src={IMAGES.Car2} alt="" className="company-product-item__icon"/>
                  </div>
                  <p className="company-product-item__text">
                    шины
                    <br/>многоцелевого
                    <br/>назначения
                  </p>
                </div>
                <div className="company-product-item">
                  <img src={IMAGES.InviteTyre3}
                       alt="шины для сельхозтехники"
                       className="company-product-item__image-tyre"
                       style={{top: '-208px'}}
                  />
                  <div className="company-product-item__icon-area">
                    <img src={IMAGES.Car3} alt="" className="company-product-item__icon"/>
                  </div>
                  <p className="company-product-item__text">
                    шины
                    <br/>для
                    <br/>сельхозтехники
                  </p>
                </div>
                <div className="company-product-item">
                  <img src={IMAGES.InviteTyre4}
                       alt="шины для спецтехники"
                       className="company-product-item__image-tyre"
                       style={{top: '-205px'}}
                  />
                  <div className="company-product-item__icon-area">
                    <img src={IMAGES.Car4} alt="" className="company-product-item__icon"/>
                  </div>
                  <p className="company-product-item__text">
                    шины
                    <br/>для
                    <br/>спецтехники
                  </p>
                </div>
                {/*<div className="company-product-item">*/}
                {/*  <div className="company-product-item__icon-area">*/}
                {/*    <img src={IMAGES.Car5} alt="" className="company-product-item__icon"/>*/}
                {/*  </div>*/}
                {/*  <p className="company-product-item__text">*/}
                {/*    шины*/}
                {/*    <br/>для*/}
                {/*    <br/>грузовиков*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
              <div className="invite-page-detail__button-area">
                <Link to="/about" className="button button--blue invite-page-detail__button-blue">
                  <span>подробнее о компании</span>
                </Link>
              </div>
            </div>

          </Container>
        </div>

      </Container>

      <Container fluid className="invite-page-about">

        <Container fluid className="invite-page-about__title">
          <div className="title-block delivery-page-payment__title-block">
            <h2>О дистрибьюторе</h2>
          </div>
        </Container>
        <Container fluid className="invite-page-about__info">

          <div className="invite-page-text-area">
            <Container>
              <p className="invite-page-text">
                <span>ООО «МТК Росберг Центр»</span> — это крупный и надежный поставщик шин, оригинальных и аналоговых
                запасных частей, а также сопутствующих услуг по легковому, грузовому шиномонтажу, ремонту легковых и
                грузовых автомобилей.
                Компания успешно функционирует более 12 лет.
                <br/><br/>
                Мы является официальным дилером более 20 крупнейших производителей шин, а также самостоятельно
                импортируем
                более 15 всемирно известных брендов по шинам.
              </p>
            </Container>
          </div>

          <div className="invite-steps-area">
            <Container>
              <div className="invite-steps">
                <div className="invite-steps__col">
                  <div className="invite-step" data-count="1">
                    Опыт работы на рынке поставок
                    шин и запасных частей более 12 лет
                  </div>
                  <div className="invite-step" data-count="2">
                    Качество, оперативность и широкий ассортимент поставляемого товара
                  </div>
                  <div className="invite-step" data-count="3">
                    Профессиональный коллектив
                  </div>
                </div>
                <div className="invite-steps__col invite-steps__col-middle">


                  <div className="invite-sert">
                    <Fancybox options={{infinite: false}}>
                      <div
                        data-fancybox="ser"
                        data-src={IMAGES.Sert7}>
                        <img src={IMAGES.Sert7}
                             alt="сертификат"
                             width="350"
                             height="383"
                        />
                      </div>
                    </Fancybox>

                  </div>
                </div>
                <div className="invite-steps__col">
                  <div className="invite-step" data-count="4">
                    Максимально сжатые сроки
                    обработки и выполнения заказов

                  </div>
                  <div className="invite-step" data-count="5">
                    Гарантия на все реализуемые
                    товары
                  </div>
                  <div className="invite-step" data-count="6">
                    Собственный склад для хранения
                    продукции
                  </div>
                </div>
              </div>
              <div className="invite-page-about__button-area">
                <a
                  className="button button--white invite-page-about__button-white"
                  href="https://mtkrosberg.ru/" target="_blank">
                  <span>На сайт «МТК Росберг Центр»</span>
                </a>
                <a
                  className="button button--red invite-page-about__button-red"
                  href="#form-invite">
                  <span>Стать дилером</span>
                </a>
              </div>
            </Container>
          </div>

        </Container>
      </Container>

      <Container fluid className="invite-page-geo">

        {/*<Container fluid className="invite-page-about__title">*/}
        {/*  <div className="title-block delivery-page-payment__title-block">*/}
        {/*    <h2>География компании</h2>*/}
        {/*  </div>*/}
        {/*</Container>*/}

        {/*<Fancybox options={{infinite: false}}>*/}
        {/*  <div*/}
        {/*    className="geo-fancy"*/}
        {/*    data-fancybox="gallery"*/}
        {/*    data-src={IMAGES.Geo}>*/}
        {/*    <img*/}
        {/*      className="invite-page-geo__map"*/}
        {/*      src={IMAGES.Geo}*/}
        {/*      alt="Карта" width="100%" height="550"/>*/}
        {/*  </div>*/}
        {/*</Fancybox>*/}

        <Container className="geo-work">
          <p className="geo-work__text">
            <span>Мы работаем</span> во всех странахТаможенного Союза!
          </p>
          <div className="geo-work__list">
            <img className="geo-work__image" src={IMAGES.Geo1} alt="россия" width="215" height="158"/>
            <img className="geo-work__image" src={IMAGES.Geo2} alt="казахстан" width="215" height="158"/>
            <img className="geo-work__image" src={IMAGES.Geo3} alt="белоруссия" width="215" height="158"/>
            <img className="geo-work__image" src={IMAGES.Geo4} alt="армения" width="215" height="158"/>
            <img className="geo-work__image" src={IMAGES.Geo5} alt="киргизия" width="215" height="158"/>
          </div>
        </Container>

      </Container>

      <FormCommunicationManager/>
    </section>
  </PageTransition>
    ;
};

export default InvitePage;
