import React, {memo, useEffect} from 'react';
import './table-product.sass'
import {IProduct} from "../../types/types";
import {EQUIPMENT} from "../../constants";
import {Price} from "../../utils/helpers";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setProductCart, getProductCart, openCart} from "../../redux/actions/cart";
import {Link} from "react-router-dom";
import {reloadProductAfterAddCart} from "../../redux/actions/products";
import {IMAGES} from "../../constants/images";

const TableProduct = ({products}) => {

  const dispatch = useDispatch()

  const handleAddProductCart = (data: IProduct) => {
    dispatch(reloadProductAfterAddCart(data))
    dispatch(setProductCart(data))
    dispatch(openCart())
  }

  return (

    <div className="cart-block table-product-block">
      <div className="table-product-area">
        <table className="table-product" width="100.2%">
          <thead className="table-product__thead">
          <tr>
            <th className="table-product__title">
              размер
            </th>
            <th className="table-product__title">
              норма
              <br/> слойности
            </th>
            <th className="table-product__title">
              тип
            </th>
            <th className="table-product__title">
              комплектация
            </th>
            <th className="table-product__title" style={{width: '18%'}}>
              индекс нагрузки
              <br/>(скорости)
            </th>
            <th className="table-product__title">
              артикул
            </th>
            <th className="table-product__title" style={{width: '9%'}}>
              цена,₽
            </th>
            <th className="table-product__title">
              добавить
              в корзину
            </th>
          </tr>
          </thead>
          <tbody className="table-product__body">
          {products.map((item: IProduct) => {
            return (
              <tr
                className="table-product__tr"
                key={item.id}>
                <td>
                  <div className="table-product__title">
                    размер
                  </div>
                  <span>
                  <Link to={`/product/${item.id}`}>
                    {item.index_size ? item.index_size : '-'}
                  </Link>
                </span>
                </td>
                <td>
                  <div className="table-product__title">
                    норма
                    слойности
                  </div>
                  <span>
                  {item.pr ? item.pr : '-'}
                </span>
                </td>
                <td>
                  <div className="table-product__title">
                    тип
                  </div>
                  <span>
                   {item.type ? EQUIPMENT[item.type] : '-'}
                </span>
                </td>
                <td>
                  <div className="table-product__title">
                    комплектация
                  </div>
                  <span>
                  {item.options ? item.options : '-'}
                </span>
                </td>
                <td>
                  <div className="table-product__title">
                    индекс нагрузки
                    (скорости)
                  </div>
                  <span>
                  {item.indexes ? item.indexes : '-'}
                </span>
                </td>
                <td>
                  <div className="table-product__title">
                    артикул
                  </div>
                  <span>
                  {item.artnumber ? item.artnumber : '-'}
                </span>
                </td>
                <td>
                  <div className="table-product__title">
                    цена,₽
                  </div>
                  <strong>
                    {item?.new_price
                      ?
                      Price(item.new_price)
                      :
                      Price(item.price)
                    }
                  </strong>
                </td>
                <td>
                <span>
                 {item?.count
                   ?
                   <Link
                     to="/cart"
                     className="button button-table">
                     <span>Товар в корзине</span>
                   </Link>
                   :
                   <button
                     type="button"
                     onClick={e => handleAddProductCart(item)}
                     className="button button-table">
                     <span>добавить</span>
                     <img className="button-table-icon" src={IMAGES.CartIconButton} alt=""/>
                   </button>
                 }
                </span>

                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>

    </div>

  );
};

export default TableProduct;
