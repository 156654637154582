import React, {useEffect, useState, useCallback, memo} from "react";
import "./drop-cart.sass";
import {IMAGES} from "../../../constants/images";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {Declination, Price} from "../../../utils/helpers";
import {
  resetModalCart,
  openCart,
  setProductCart,
  removeProductUnitCart,
} from "../../../redux/actions/cart";
import {imageUrl} from "../../../constants";
import {IProduct} from "../../../types/types";

const DropCart = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const cart = useSelector((state: RootState) => state.cart);

  const handleGoToCart = () => {
    dispatch(openCart());
    navigate("/cart");
  };

  const handleAddProductCart = (data: IProduct) => {
    dispatch(setProductCart(data));
  };

  const handleRemoveProductCart = (data: IProduct) => {
    dispatch(removeProductUnitCart(data));
  };

  const totalPriceProduct = () => {
    const _price = cart?.last_add_product_cart?.new_price
      ? cart?.last_add_product_cart?.new_price
      : cart?.last_add_product_cart?.price;
    return cart?.last_add_product_cart?.count * _price;
  };

  return (
    <div className="drop-cart">
      <div className="drop-cart__header">
        <span className="drop-cart__title">Товар добавлен в корзину</span>
        <button className="button" onClick={() => dispatch(resetModalCart())}>
          <img src={IMAGES.CloseIcon} alt="Закрыть" />
        </button>
      </div>
      <div className="drop-cart__content">
        <div className="cart-product-mini">
          <div className="cart-product-mini__image">
            <img
              src={`${imageUrl}${cart?.last_add_product_cart?.model?.picture}`}
              alt={cart?.last_add_product_cart.name}
            />
          </div>
          <Link
            to={`/product/${cart?.last_add_product_cart.id}`}
            onClick={() => dispatch(resetModalCart())}
            className="cart-product-mini__name"
          >
            <span>{cart?.last_add_product_cart.name}</span>
          </Link>

          <div className="count">
            <button
              className="button"
              type="button"
              disabled={cart?.last_add_product_cart?.count === 1 && true}
              onClick={() =>
                handleRemoveProductCart(cart?.last_add_product_cart)
              }
            >
              &ndash;
            </button>

            <input
              type="text"
              value={cart?.last_add_product_cart?.count}
              className="form-control"
              readOnly
            />

            <button
              type="button"
              className="button"
              onClick={() => handleAddProductCart(cart?.last_add_product_cart)}
            >
              +
            </button>
          </div>

          <div className="cart-product-mini__total-price">
            <div className="cart-product-mini__price">
              <span className="cart-mini-price">
                {cart?.last_add_product_cart?.new_price
                  ? Price(cart?.last_add_product_cart?.new_price)
                  : Price(cart?.last_add_product_cart?.price)}
              </span>
              <span className="cart-mini-rub"> ₽</span>
            </div>
            {cart?.last_add_product_cart?.new_price && (
              <div className="cart-product-mini__old-price">
                <span className="cart-mini-old-price">
                  {cart?.last_add_product_cart?.price}
                </span>
                <span className="cart-mini-old-rub"> ₽ </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="drop-cart__footer">
        <div className="drop-cart-info">
          <span className="drop-cart-info__count">общая сумма</span>
          <div className="drop-cart-info__price">
            <span>{Price(totalPriceProduct())}</span> ₽
          </div>
        </div>
        <div className="drop-cart__buttons ">
          <button
            onClick={() => dispatch(resetModalCart())}
            className="button  button--gray drop-cart__button"
          >
            <span>Продолжить покупки</span>
          </button>
          <button
            onClick={() => handleGoToCart()}
            className="button  button--red drop-cart__button"
          >
            <span>Перейти в корзину</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(DropCart);
