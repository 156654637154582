import React, {memo, useEffect, useRef} from "react";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IProduct} from "../../../types/types";
import {
  removeProductCart, removeProductUnitCart, setDeleteProduct,
  setProductCart, toggleDropWindowCart
} from "../../../redux/actions/cart";
import {RootState} from "../../../redux/store";
import {Price} from "../../../utils/helpers";
import {IMAGES} from "../../../constants/images";
import {CSSTransition} from "react-transition-group";
import {imageUrl} from "../../../constants";
import {toggleModalDelete} from "../../../redux/actions/modals";
import useClickOutside from "../../../hooks/useClickOutside";

const CartProductWindow = () => {

  const dispatch = useDispatch();

  const location = useLocation();

  const cart = useSelector((state: RootState) => state.cart);

  const modals = useSelector((state: RootState) => state.modals);

  const cart_drop_ref = useRef<any>();

  useClickOutside(cart_drop_ref, () => dispatch(toggleDropWindowCart(false)));

  useEffect(() => {
    dispatch(toggleDropWindowCart(false));
  }, [location.pathname]);

  const handleAddProductCart = (data: IProduct) => {
    dispatch(setProductCart(data));
  };
  const handleRemoveProductCart = (data: IProduct) => {
    dispatch(removeProductUnitCart(data));
  };

  const handleOpenModalDelete = (data: IProduct) => {
    dispatch(toggleModalDelete(!modals.modal_delete));
    dispatch(setDeleteProduct(data));
  };

  return (

    <CSSTransition
      in={cart.drop_window_cart}
      timeout={300}
      classNames="alert"
      unmountOnExit>
      <div className="cart-product-window" ref={cart_drop_ref}>
        <div className="cart-product-window__content">
          <div className="cart-product-window__list">
            {cart.products.length === 0
            &&
            <div className="cart-stub">
              <div className="cart-stub__content">
                <p className="cart-stub__text">
                  <span className="black">В корзине{" "}</span>
                  <span className="red">нет ни одного{" "}</span> <span className="black">товара</span>

                  <span className="cart-stub__text-info">
                    Вы можете
                  подобрать интересующие вас товары в нашем каталоге товаров.
                  </span>
                </p>
                <div className="cart-stub__buttons d-flex justify-content-center">
                  <Link
                    to="/catalog"
                    onClick={() => {
                      dispatch(toggleDropWindowCart(false));
                    }}
                    className="button button--red cart-stub__button">
                      <span>
                        Подобрать товары
                      </span>
                  </Link>
                </div>
                {/*<div className="cart-stub__image-block d-flex justify-content-center">*/}
                {/*  <img src={IMAGES.CartStub} alt="Пустая корзина"/>*/}
                {/*</div>*/}
              </div>
            </div>
            }

            {cart.products.length > 0 && cart.products.map((item: IProduct, index) => {
              return (
                <div
                  key={item.id}
                  className="cart-product-window__item cart-product-window-item">
                  <button
                    onClick={() => handleOpenModalDelete(item)}
                    className="button cart-product-window-item__delete">
                    <img src={IMAGES.Icon1} alt="Удалить"/>
                  </button>
                  <div className="cart-product-window-item__image">
                    <img
                      src={`${imageUrl}${item?.model?.picture}`}
                      alt={item.name}/>
                  </div>
                  <div className="cart-product-window-item__content">
                    <Link to={`product/${item.id}`} className="cart-product-window-item__name">
                      {item.name}
                    </Link>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="d-flex flex-column">
                        {item.new_price
                          ?
                          <>
                            <del className="old-price">
                              {Price(item?.price)}
                              <span>₽</span>
                            </del>
                            <p className="price">
                              {Price(item?.new_price)}
                              <span>₽</span>
                            </p>
                          </>
                          :
                          <p className="price">
                            {Price(item?.price)}
                            <span>₽</span>
                          </p>
                        }

                      </div>
                      <div className="count">
                        <button className="button"
                                disabled={item.count === 1 && true}
                                onClick={() => handleRemoveProductCart(item)}
                        >
                          &ndash;
                        </button>
                        <input type="text" value={item.count} className="form-control" readOnly/>
                        <button className="button" onClick={() => handleAddProductCart(item)}>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
          {cart.products.length > 0
          &&
          <div className="cart-product-window-item__footer">
            <div
              className="cart-product-mini__price m-0 d-flex cart-product-window-item__price">
              <p>Всего</p>
              <span className="cart-mini-price">{Price(cart.total_price)}</span>
              <span className="cart-mini-rub">₽</span>
            </div>
            <Link to="/cart" className="button button--blue cart-product-window-item__button-blue">
              <span>
                В корзину
              </span>
            </Link>
          </div>
          }
        </div>
      </div>
    </CSSTransition>
  );
};

export default memo(CartProductWindow);
