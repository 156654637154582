import React, {memo, useEffect, useState} from "react";
import {FiltersFields} from "../../constants/filters";
import {useDispatch, useSelector} from "react-redux";
import {createUrl, loadingFilter, setFilter} from "../../redux/actions/filters";
import {DebounceInput} from 'react-debounce-input';

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import api from "../../api";

const FilterRange = ({filter_field, filter_data}) => {
  const {price_from, price_to} = filter_data;
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [inited, setInited] = useState(true);

  const handleResetUnitFilter = () => {
    setFrom(filters.default_value_price_from);
    setTo(filters.default_value_price_to);

    handleUrl(filters.default_value_price_from, filters.default_value_price_to);
  };

  const handleUrl = async (f, t) => {
    const _data = {
      value: [f, t],
      filter_field: filter_field,
      type: "range",
    };
    dispatch(createUrl(_data));
  }

  const onSelectChange = (value) => {
    setFrom(value[0]);
    setTo(value[1]);
    handleUrl(value[0], value[1])
  };

  useEffect(() => {
    if (!inited) {
      api
        .getFilterDefaultPricesRange()
        .then((res) => {
          dispatch({ type: "SET_DEFAULT_PRICES", payload: res.prices });
          setInited(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [inited]);

  useEffect(() => {
    setFrom(price_from);
    setTo(price_to);
  }, [price_from, price_to])

  const onSliderChange = (value) => {
    setFrom(value[0]);
    setTo(value[1]);
  }

  const onChangeInput = (e) => {
    if (e.target.name === 'price_from') {
      setFrom(e.target.value)
      handleUrl(e.target.value, to)
    }
    if (e.target.name === 'price_to') {
      setTo(e.target.value)
      handleUrl(from, e.target.value)
    }
  }

  return (
    <div className="filter">
      <div className="filter__header filter-header">
        <div className="filter-header__title-block">
          <span className="filter-header__title">
            {FiltersFields[filter_field]}
          </span>
          <button
            type="button"
            onClick={() => handleResetUnitFilter()}
            className="button filter-header__button-reset"
          >
            Сбросить
          </button>
        </div>
      </div>

      {inited && <>
        <div className="filter-checkbox-content filter__content filter-content">
          <div className="filter-range-price-inputs">
            <DebounceInput
              type="text"
              debounceTimeout={1000}
              min={filters.default_value_price_from}
              max={to}
              value={from || filters.default_value_price_from}
              forceNotifyByEnter={true}
              forceNotifyOnBlur={true}
              name="price_from"
              onChange={onChangeInput}
            />

            <DebounceInput
              type="text"
              debounceTimeout={1000}
              min={from}
              max={filters.default_value_price_to}
              value={to || filters.default_value_price_to}
              forceNotifyByEnter={true}
              forceNotifyOnBlur={true}
              name="price_to"
              onChange={onChangeInput}
            />
          </div>

          <Slider
            range
            className="range-price"
            allowCross={false}
            min={filters.default_value_price_from}
            max={filters.default_value_price_to}
            defaultValue={[filters.default_value_price_from, filters.default_value_price_to]}
            value={[from || filters.default_value_price_from, to || filters.default_value_price_to]}
            draggableTrack={true}
            step={0}
            onAfterChange={onSelectChange}
            onChange={onSliderChange}
          />
          <hr className="filter__line"/>
        </div>
      </>}


    </div>
  );
};

export default FilterRange;
