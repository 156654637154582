import React, {memo} from 'react';
import {Link} from "react-router-dom";
import {imageUrl} from '../../constants'
import '../product-card/product-card.sass'

const ModelCard = ({model}) => {

  const {id, name, picture, brand, section, type} = model

  return (
    <div className="model-card-area">
      <div className="product-card model-card">
        <div className="product-card__image-block">
          <img src={`${imageUrl}${picture}`} alt="" width="143" height="200"/>
        </div>
        <div className="product-card__info-block">
          <div className="model-card__name" title={name}>
            {name}
          </div>
          <div className="model-card__info">
            {type}
          </div>
          {/*<div className="model-card__link">*/}
          {/*  {brand?.name}*/}
          {/*</div>*/}
        </div>
        <div className="product-card__button-block">
          <Link
            to={`/model/${id}`}
            className="button button--blue product-card__button-blue">
            <span>Подробнее</span>
          </Link>
        </div>
      </div>
    </div>

  );
};

export default memo(ModelCard);
