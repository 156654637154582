import React, {useEffect, useRef, createRef, memo} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import InputMask from "react-input-mask";
import {useDispatch, useSelector} from "react-redux";
import {changeFromInput, setErrors} from "../../../../../../carlisle/src/redux/actions/making_order";
import {RootState} from "../../../../../../carlisle/src/redux/store";
import {IMAGES} from "../../../../../../carlisle/src/constants/images";

const MakingOrder = ({error, setError}) => {
  // console.log('error-', error)
  const dispatch = useDispatch();
  const making_order = useSelector((state: RootState) => state.making_order);
  const handleChangeInput = (field: any, value: any) => {
    const data = {
      field, value
    };
    dispatch(changeFromInput(data));
  };
  const ref_fio = useRef(null);
  const ref_phone = useRef(null);
  const ref_agreement = useRef(null);
  const ref_form = useRef(null);


  useEffect(() => {
    if (error === "") return;
    if (error.fio !== "" && making_order.fio === "") {
      ref_fio?.current?.focus();
      setError("");
    } else if (error.phone !== "" && making_order.phone === "") {
      ref_phone?.current?.focus();
      setError("");
    } else if (error.agreement !== false) {
      ref_agreement?.current?.scrollIntoView();
      setError("");
    }
    // ref_form?.current?.scrollIntoView()
    // if(error === 'fio'){
    //   ref_fio?.current?.focus()
    // }
    // console.log('есть ошибка', error)
    // if(error === 'phone'){
    //   ref_phone?.current?.focus()
    // }
    // if(error === 'agreement'){
    //   ref_agreement?.current?.focus()
    // }
  }, [error]);


  return (
    <Container fluid className="cart-area">
      <Container className="cart-area__container">
        <div className="cart-product-list-block making-order">
          <div className="cart-block__title-block">
            <h2 className="cart-block__title">Оформление заказа</h2>
          </div>

          <div className="form-order">
            <h4 className="form-order__title">Ваши контактные данные</h4>

            <div className="form-order__row-area">
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="fio" className="form-label">
                      фио*
                    </label>
                    <input
                      ref={ref_fio}
                      // required
                      value={making_order.fio}
                      type="text"
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                      className="form-control form-order__form-control"
                      aria-describedby="fio"
                    />
                    {making_order.errors?.fio && (
                      <div className="modal-order-call__form-error">
                        {making_order.errors?.fio}
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="phone" className="form-label">
                      номер телефона*
                    </label>
                    <InputMask
                      ref={ref_phone}
                      mask="+7 (999) 999 99 99"
                      name="phone"
                      placeholder="+7"
                      maskPlaceholder=""
                      className="form-control form-order__form-control"
                      aria-describedby="phone"
                      value={making_order.phone}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                    />
                    {making_order.errors?.phone && (
                      <div className="modal-order-call__form-error">
                        {making_order.errors?.phone}
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="email" className="form-label">
                      email
                    </label>
                    <input
                      type="email"
                      value={making_order.email}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                      className="form-control form-order__form-control"
                      aria-describedby="email"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label
                      htmlFor="phone"
                      className="form-label form-label-hidden"
                    >
                      &nbsp;
                    </label>

                    <div className="form-check">
                      <label htmlFor="agreement" className="agreement">

                        <input
                          ref={ref_agreement}
                          checked={making_order.agreement}
                          onChange={(e) =>
                            handleChangeInput(
                              e.target.getAttribute("aria-describedby"),
                              !making_order.agreement
                            )
                          }
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="agreement"
                          aria-describedby="agreement"
                        />

                        <div className="checkbox"/>
                        Я согласен с {" "}
                        <div className="form-check__agreem">
                          <a
                            href="/politika-konfidentsialnosti"
                            target="_blank"
                          >
                            политикой конфиденциальности
                          </a>
                        </div>
                      </label>
                    </div>
                    {making_order.errors?.agreement && (
                      <div className="modal-order-call__form-error">
                        {making_order.errors?.agreement}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="form-order__row-area form-order__row-area--last">
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="name-organization" className="form-label">
                      наименование организации
                    </label>
                    <input
                      type="text"
                      value={making_order.organization}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                      className="form-control form-order__form-control"
                      aria-describedby="organization"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="legal-address" className="form-label">
                      юридический адрес
                    </label>
                    <input
                      type="text"
                      value={making_order.legal_address}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                      className="form-control form-order__form-control"
                      aria-describedby="legal_address"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="inn" className="form-label">
                      инн
                    </label>
                    <input
                      type="text"
                      value={making_order.inn}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                      className="form-control form-order__form-control"
                      aria-describedby="inn"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div className="form-order__form">
                    <label htmlFor="comments" className="form-label">
                      комментарии
                    </label>
                    <input
                      type="text"
                      value={making_order.comment}
                      onChange={(e) =>
                        handleChangeInput(
                          e.target.getAttribute("aria-describedby"),
                          e.target.value
                        )
                      }
                      className="form-control form-order__form-control"
                      aria-describedby="comment"
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <p className="fields-mandatory">
              * Поля являются обязательными для заполнения
            </p>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default memo(MakingOrder);
