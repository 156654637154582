import React from 'react';
import {IMAGES} from '../../constants/images';
import {Container} from 'react-bootstrap';

const AboutGrid = () => {
  return (
    <div className="about-grid">

      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {'>1 000'}
        </h6>
        <span className="about-grid__info">единиц</span>
        <span className="about-grid__desc">Ассортимент шин <br/>
для сельхозтехники</span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About1} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {'>3 400'}
        </h6>
        <span className="about-grid__info">сотрудников</span>
        <span className="about-grid__desc">Профессионалы <br/>
в своей области </span>
      </div>
      {/*<div className="about-grid__block">*/}
      {/*  <img src={IMAGES.About2} alt="" className="about-grid__image"/>*/}
      {/*</div>*/}


      <div className="about-grid__block">
        <img src={IMAGES.About2} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {'1917'}
        </h6>
        <span className="about-grid__info">год основания</span>
        <span className="about-grid__desc">Более 100 лет компания  —<br/>
лидер на рынке </span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About3} alt="" className="about-grid__image"/>
      </div>
      {/*<div className="about-grid__block">*/}
      {/*  <h6 className="about-grid__title">*/}
      {/*    {">250"}*/}
      {/*  </h6>*/}
      {/*  <span className="about-grid__info">сотрудников</span>*/}
      {/*  <span className="about-grid__desc">Профессионалы в своей области </span>*/}
      {/*</div>*/}

      {/*<div className="about-grid__block">*/}
      {/*  <img src={IMAGES.About4} alt="" className="about-grid__image"/>*/}
      {/*</div>*/}
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {'>1 000'}
        </h6>
        <span className="about-grid__info">единиц</span>
        <span className="about-grid__desc">Ассортимент шин <br/>
для мототехники </span>
      </div>
      <div className="about-grid__block">
        <img src={IMAGES.About4} alt="" className="about-grid__image"/>
      </div>
      <div className="about-grid__block">
        <h6 className="about-grid__title">
          {'>1 000'}
        </h6>
        <span className="about-grid__info">единиц</span>
        <span className="about-grid__desc">Ассортимент шин <br/>для квадротехники</span>
      </div>
    </div>
  );
};

export default AboutGrid;
