import {EffectFade, Pagination, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {IMAGES} from "../../constants/images";

import {Col, Container, Row} from "react-bootstrap";
import React, {memo, useState} from "react";

const SliderMain = () => {

  const imgs = [IMAGES.BannerSlide1, IMAGES.ImgBanner];

  return (
    <div className="slider-main slider-models">

      <Swiper
        centeredSlides={true}
        effect={"fade"}
        modules={[EffectFade, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={"auto"}
        loop={true}
        speed={800}
        autoplay={{
          delay: 3000,
          // disableOnInteraction: false,
        }}
        pagination={{clickable: true}}
        // allowTouchMove={false}
      >
        <SwiperSlide className="slider2">
          <Container className="slider-main__container ">
            <Row>
              <Col>
                <div className="slider-info">

                  <img
                    className="slider-info__logo" src={IMAGES.logoBanner} alt="carlisle" />

                  <p className="slider-info__p">
                    изменил название бренда в пользу
                  </p>
                  <img
                    className="slider-info__logo-new"
                    src={IMAGES.logoBannerNew} alt="carlisle-new" />
                  <p className="slider-info__p slider-info__text">
                    Подробная информация на официальном
                    сайте производителя —
                    <a
                      target="_blank"
                      href="https://carlstartires.eu/2023/07/05/the-carlstar-group-announces-brand-transition/">
                      CARLSTAR
                      GROUP
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <picture>
            <source srcSet={IMAGES.ImgBannerSx} media="(max-width: 767.9px)"/>
            <source srcSet={IMAGES.ImgBannerMd} media="(max-width: 991.9px)"/>
            <img
              className="main-banner__image page-main-banner"
              srcSet={IMAGES.ImgBanner} alt="Для сельхозтехники"/>
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <Container className="slider-main__container">
            <Row>
              <Col>
                <div className="main-banner__title-block title-bg">
                  <h1 className="main-banner__title">шины Carlisle
                    <br />Для сельхозтехники</h1>
                  <p className="main-banner__sub-title">
                    оптимальная работа при любых условиях
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <img
            src={IMAGES.BannerSlide1}
            className="main-banner__image page-main-banner"
            alt="Для сельхозтехники"
          />
        </SwiperSlide>

      </Swiper>

    </div>
);
};

export default memo(SliderMain);
