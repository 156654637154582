import React, {memo} from 'react';
import {Container} from "react-bootstrap";
import {IMAGES} from "../../constants/images";

const ModelBanner = ({section, type = null}) => {

  return (
    <>
      <Container fluid className="product-page-banner">

        {/*Все*/}
        {/*{section === undefined*/}
        {/*&&*/}
        {/*<img*/}
        {/*  src={IMAGES.CatalogBgAll}*/}
        {/*  className="main-banner__image"*/}
        {/*  alt="Все модели"/>*/}
        {/*}*/}

        {/*Спецтехника*/}
        {/*{section?.id === 27*/}
        {/*&&*/}
        {/*<img*/}
        {/*  src={IMAGES.ModelsPage3}*/}
        {/*  className="main-banner__image"*/}
        {/*  alt={section?.mainTitle}*/}
        {/*/>*/}
        {/*}*/}

        {/*Сельхозтехника*/}
        {/*{section?.id === 14*/}
        {/*&&*/}
        {/*<img*/}
        {/*  src={IMAGES.ModelsPage2}*/}
        {/*  className="main-banner__image"*/}
        {/*  alt={section?.mainTitle}*/}
        {/*/>*/}
        {/*}*/}

        {/*Грузовая техника*/}
        {/*{section?.id === 18*/}
        {/*&&*/}
        {/*<img*/}
        {/*  src={IMAGES.ModelsPage1}*/}
        {/*  className="main-banner__image"*/}
        {/*  alt={section?.mainTitle}*/}
        {/*/>*/}
        {/*}*/}
        {type === 'products'
          ?
          <img
            src={IMAGES.ModelsPage1}
            className="main-banner__image"
            alt={section?.mainTitle}
          />
          :
          <img
            src={IMAGES.ModelsBgNew}
            className="main-banner__image"
            alt={section?.mainTitle}
          />
        }

        <Container className=" product-page-banner__container">
          <div className="main-banner__title-block product-page-banner__title-block">
            <h1 className="main-banner__title product-page-banner__title">
              {type === 'products'
              ?
                'Каталог'
              :
                section?.mainTitle ? section.mainTitle : 'Все модели'
              }
            </h1>
          </div>
        </Container>

      </Container>

    </>
  );
};

export default memo(ModelBanner);
