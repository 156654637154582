import {Link, useLocation} from 'react-router-dom';
import {IMAGES} from '../constants/images';
import {Row, Col, Container} from 'react-bootstrap';
import ChooseTyre from '../components/choose-tyre/ChooseTyre';
import SliderModels from '../components/slider-models/SliderModels';
import AboutGrid from '../components/about-grid/AboutGrid';
import ContactMap from '../components/map/ContactMap';
import React, {useEffect} from 'react';
import api from '../api';
import {loadingFilter, setFilter} from '../redux/actions/filters';
import {useDispatch, useSelector} from 'react-redux';
import NavigationModel from '../components/navigation-model/NavigationModel';
import SliderMain from '../components/slider-models/SliderMain';
import {Helmet} from 'react-helmet';
import PageTransition from '../components/PageTransition';
import SliderAbout from '../components/slider-models/SliderAbout';
import AboutMain from '../components/about-main/AboutMain';

const MainPage = (props) => {

  const dispatch = useDispatch();

  const location = useLocation();

  const filters = useSelector((state) => state.filters);

  const _url = filters.url !== null ? filters.url : location.search;

  const init = async () => {
    await api.getFilter(_url).then((res) => {
      dispatch(setFilter(res));
      dispatch(loadingFilter(false));
    }).catch(e => {
      console.log(e);
    });
  };

  useEffect(() => {
    return () => {
      dispatch({type: 'RESET_FILTER_VALUE'});
    };
  }, []);

  useEffect(() => {
    init();
  }, [_url]);
  // console.log(location)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
      </Helmet>
      <Container fluid className="main-banner">

        <Link to="/contacts/invite" className="main-banner__invite">
          <img
            className="main-banner__invite-image"
            src={IMAGES.Invite}
            alt="Приглашение"/>
        </Link>

        <SliderMain/>

        <Container>
          <Row>
            <Col>
              <ChooseTyre classBem="main-banner"/>
            </Col>
          </Row>
        </Container>
      </Container>

      {/*<Container fluid className="navigation-area d-md-block d-none">*/}
      {/*  <Container>*/}
      {/*    <ul className="navigation">*/}
      {/*      <li className="navigation__item">*/}
      {/*        <Link to="/about/technologies" className="navigation__link">*/}
      {/*          Технологии*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li className="navigation__item">*/}
      {/*        <Link to="/about/about-company" className="navigation__link">*/}
      {/*          О компании*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li className="navigation__item">*/}
      {/*        <Link to="/delivery" className="navigation__link">*/}
      {/*          Доставка и оплата*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li className="navigation__item">*/}
      {/*        <Link to="/contacts/contacts-company" className="navigation__link">*/}
      {/*          Контакты*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </Container>*/}
      {/*</Container>*/}

      {/*<NavigationModel/>*/}

      <AboutMain/>

      <Container fluid className="models-area">
        <Container>
          <div className="title-block">
            <h2 className="title">Популярные модели</h2>
            {/*<Link to="/" className="title-block__link">*/}
            {/*  Смотреть все*/}
            {/*</Link>*/}
          </div>
          <SliderModels/>
        </Container>
      </Container>


      <Container fluid className="delivery-area bg-gray">
        <Container>
          <h2 className="delivery-area__title">Доставка и оплата</h2>

          <div className="delivery">
            <div className="delivery__item ">
              <img src={IMAGES.Image1} alt="" className="delivery__arrow"/>
              <span className="delivery__title">выбор товара</span>
              <img
                className="delivery__image"
                src={IMAGES.Delivery1}
                alt="С чего начать"
              />
              <p className="delivery__text">
                Выберите интересующий
                вас товар
              </p>
              <div className="delivery__list">
                <p>на сайте</p>
                <p>консультация по телефону</p>

              </div>
            </div>

            <div className="delivery__item ">
              <img src={IMAGES.Image1} alt="" className="delivery__arrow"/>
              <span className="delivery__title">Оплата</span>
              <img
                className="delivery__image"
                src={IMAGES.Delivery3}
                alt="наличие товара"
              />
              <p className="delivery__text">
                Оплата производится любым из перечисленных способов
              </p>
              <div className="delivery__list">
                <p>безналичный расчет
                  <br/>( для юр.лиц )</p>
                <p>банковский перевод</p>
                <p>
                  наличный расчет
                </p>
              </div>
            </div>
            <div className="delivery__item ">
              <span className="delivery__title">доставка</span>
              <img
                className="delivery__image"
                src={IMAGES.Delivery5}
                alt="наличие товара"
              />
              <p className="delivery__text">
                Получить товар вы сможете
                несколькими способами
              </p>
              <div className="delivery__list">
                <p>с помощью любых тк</p>
                <p>с помощью нашего транспорта</p>
                <p>самовывоз</p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <Link
              to="/delivery"
              className="button button--blue delivery-area__button-blue"
            >
              <span>Подробнее о доставке</span>
            </Link>
          </div>
        </Container>
      </Container>

      <Container fluid className="contact-area">
        <img src={IMAGES.ContactBlock} alt="" className="contact-area__image"/>
        <Container className="contact-area__block">
          <div className="title-block">
            <h2 className="delivery-area__title">Контакты</h2>
            <Link to="/contacts/contacts-company" className="title-block__link">
              Смотреть все
            </Link>
          </div>
          <p className="contact-area__info">
            Доставка вашего товара транспортными компаниями осуществляется по территории России и в страны СНГ.
            Рассчет стоимости доставки осуществляется у представителей транспортной компании или нашего менеджера.
          </p>
        </Container>
      </Container>

      <div className="main-page-map">
        <ContactMap withLink={true}/>
      </div>

    </>
  );
};

export default MainPage;
