import React, {useRef, createRef, useState, useEffect, memo} from 'react';
import {Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/controller';
import {IMAGES} from "../../constants/images";

const SliderAbout = () => {

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const swiperRef = useRef(null)

  return (
    <div className="slider-models slider-about">
      <Swiper
        ref={swiperRef}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[ Autoplay]}
        spaceBetween={10}
        loop={true}
        speed={800}
        slidesPerView={1}
        slidesPerGroup={1}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{clickable: true}}
      ><SwiperSlide>
        <div className="slider-about__slide">
          <img src={IMAGES.SlideAbout4} alt=""/>
          <div className="slider-about__info">
            Буквы на боковой стенке меняются, но все остальное остается прежним
          </div>
        </div>
      </SwiperSlide>

        <SwiperSlide>
          <div className="slider-about__slide">
            <img src={IMAGES.SlideAbout1} alt=""/>
            <div className="slider-about__info">
              Более 3400 сотрудников на 17 предприятиях
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slider-about__slide">
            <img src={IMAGES.SlideAbout2} alt=""/>
            <div className="slider-about__info">
              Более 1 000 единиц шин для сельхозтехники
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slider-about__slide">
            <img src={IMAGES.SlideAbout3} alt=""/>
            <div className="slider-about__info">
              Более 300 сотрудников
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default memo(SliderAbout);
