import {Link, Route, Routes, useLocation} from "react-router-dom";
import React, {Suspense, useEffect} from "react";
import axios from "axios";
import ScrollToTop from "./hooks/ScrollToTop";
import MainLayout from "./layouts/MainLayout";
import {getProductCart} from "./redux/actions/cart";
import {useDispatch} from "react-redux";
import {getMakingOrder} from "./redux/actions/making_order";
import {routes} from "./routeList";
import {IMAGES} from "./constants/images";

const MainRouter = () => {
  const dispatch = useDispatch();

  // axios.defaults.baseURL = "https://starmaxx-tyres.ru/";
  axios.defaults.baseURL = "https://carlisle-tyres.ru/";
  // axios.defaults.baseURL = "http://192.168.0.125:8080";

  useEffect(() => {
    dispatch(getProductCart());
    dispatch(getMakingOrder());
  }, []);

  const routeList = routes.map((route) => {
    return <Route
      key={route.path}
      path={route.path}
      element={route.element}
    />;
  });

  return (
    // <Suspense fallback={<p>Loading...</p>}>

    <MainLayout>
      <ScrollToTop>
        {useLocation().pathname !== '/'
        &&
        <Link to="/contacts/invite" className="invite">
          <img
            width="62"
            height="175"
            className="main-banner__invite-image"
            src={IMAGES.InviteDiler}
            alt="Приглашение" />
        </Link>
        }

        <Routes>
          {routeList}
        </Routes>

      </ScrollToTop>
    </MainLayout>
    // </Suspense>
  );
};

export default MainRouter;
