import React, {memo} from 'react';
import './cart-item.sass'

import {Link} from "react-router-dom";
import {IProduct} from "../../types/types";
import {
  removeProductCart,
  removeProductUnitCart,
  selectProductUnitCart, setDeleteProduct,
  setProductCart
} from "../../redux/actions/cart";
import {useDispatch, useSelector} from "react-redux";
import {Price} from "../../utils/helpers";
import {toggleModalDelete} from "../../redux/actions/modals";
import {RootState} from "../../redux/store";
import {imageUrl} from "../../constants";

const CartItem = ({product}) => {

  const {id, name, artnumber, brand, price, new_price, count, select, model} = product

  const dispatch = useDispatch()

  const modals = useSelector((state: RootState) => state.modals);

  const handleAddProductCart = (data: IProduct) => {

    dispatch(setProductCart(data))
  }

  const handleRemoveProductCart = (data: IProduct) => {
    dispatch(removeProductUnitCart(data))
  }

  const handleOpenModalDelete = (data: IProduct) => {
    dispatch(toggleModalDelete(!modals.modal_delete))
    dispatch(setDeleteProduct(data))
  }

  const handleSelectProductUnitCart = (data: IProduct) => {
    dispatch(selectProductUnitCart(data))
  }

  const priceCount = () => {
    const _price = +new_price ? +new_price : +price
    const _pricecount = count * _price
    return Price(_pricecount)
  }

  return (
    <div className="cart-item cart-item-new">

      {/*<div className="cart-item__col-check">*/}

      {/*  <div className="form-check form-check-select-product">*/}
      {/*    <input className="form-check-input"*/}
      {/*           checked={!!select}*/}
      {/*           type="checkbox"*/}
      {/*           onChange={()=>handleSelectProductUnitCart(product)}*/}
      {/*    />*/}
      {/*  </div>*/}

      {/*</div>*/}

      <div className="cart-item__col-image">
        <div className="cart-item__image-area">
          <img src={`${imageUrl}${model.picture}`} width="135" height="206" alt={name}/>
        </div>
      </div>

      <div className="cart-item__col-info">

        <div className="cart-item__info cart-item-info">
          <Link to={`/product/${id}`} className="cart-item-info__name">
            {name}
          </Link>

          <div className="cart-item-info__content">
            <div className="cart-item-info__col">
              <div className="property-list">
                {/*<div className="property-item">*/}
                {/*  <span className="property-item__name">Типоразмер</span>*/}
                {/*  <span className="property-item__info">7.50 - 16</span>*/}
                {/*</div>*/}
                <div className="property-item">
                  <span className="property-item__name">Артикул</span>
                  <span className="property-item__info">{artnumber}</span>
                </div>
                <div className="property-item">
                  <span className="property-item__name">Бренд</span>
                  <span className="property-item__info"><strong>{brand?.name}</strong></span>
                </div>
              </div>
            </div>
            <div className="cart-item-info__col d-flex align-items-center cart-item-info__col--mr">

              <div className="cart-price">
                <span className="price">{Price(new_price ? new_price : price)}</span>{" "}
                <span className="rub">₽</span>
                <span className="product-count"> (шт)</span>
              </div>

              <div className="cart-item-info__count-block">
                <div className="count">
                  <button className="button"
                          type="button"
                          disabled={count === 1 && true}
                          onClick={() => handleRemoveProductCart(product)}>
                    &ndash;
                  </button>
                  <input type="text" value={count} className="form-control" readOnly/>
                  <button type="button" className="button" onClick={() => handleAddProductCart(product)}>
                    +
                  </button>
                </div>
                <div className="cart-price">
                  <span className="price">{priceCount()}</span>{" "}<span
                  className="rub">₽</span>
                </div>
              </div>
            </div>
          </div>
          <div className="cart-item-info__footer">
            <button type="button" className="button cart-item-info__button-remove"
                    onClick={() => handleOpenModalDelete(product)}>
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CartItem);
