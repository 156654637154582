import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import SliderAbout from '../slider-models/SliderAbout';
import {Link} from 'react-router-dom';
import {Autoplay} from 'swiper';
import {IMAGES} from '../../constants/images';

const AboutMain = () => {
  return (
    <Container fluid className="main-about">
      <Container>
        <Row>
          <Col xl={6} md={6}>
            {/*<AboutGrid/>*/}
            <SliderAbout/>
          </Col>
          <Col xl={6} md={6}>
            <div className="main-about__body">
              <h2 className="main-about__title">О производителе</h2>
              <h3 className="main-about__sub-title">
                CARLSTAR GROUP
              </h3>
              <div className="main-about__info" style={{marginBottom: "25px"}}>
                В 2023 году <strong>CARLSTAR GROUP</strong> представила эволюцию бренда CARLISLE направленную на то,
                чтобы обеспечить
                компании постоянное превосходство и рост в будущем.
                <div className="main-about__pre" />
                В течение следующих нескольких месяцев Carlstar Group будет переводить продукты под брендом Carlisle на
                бренд Carlstar.
                Продукция с новым логотипом начнет поступать на наши склады начиная с сентября 2023 года.
                <div className="main-about__pre" />
                Представители CARLSTAR заявляют, что смена названия никак не повлияла на качество изготавливаемой
                продукции
                и на политику компании в целом: <strong>
                <i>
                  "Буквы на боковой стенке меняются, но все остальное остается прежним",
                </i>
              </strong> - сообщают они.
                <div className="main-about__pre" />
                Подробная информация на официальном сайте производителя —
                <a
                  target="_blank"
                  href="https://carlstartires.eu/2023/07/05/the-carlstar-group-announces-brand-transition/">
                  CARLSTAR GROUP
                </a>
              </div>

              <Link
                to="/about"
                className="button  button--blue main-about__button-blue"
              >
                <span>Подробнее о компании</span>
              </Link>

            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default AboutMain;
