import React, {useState} from "react";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import {Container, Row, Col} from "react-bootstrap";
import {IMAGES} from "../constants/images";
import AboutGrid from "../components/about-grid/AboutGrid";
import {Helmet} from "react-helmet";
import PageTransition from "../components/PageTransition";
import AboutBlockAboutPage from '../components/about-main/AboutBlockAboutPage';

const AboutCompanyPage = (props) => {
  // console.log(props)

  const list = [
    {
      title: "Шины для любых дорог",
      text: "Шины Starmaxx чаще всего выбирают владельцы крупногабаритных машин и техники, эксплуатируемой в непроходимых условиях бездорожья, работы на лесных и полевых территориях. ",
      image: IMAGES.Advantages1
    },
    {
      title: "высокое качество",
      text: "Благодаря высоким показателям качества покрышки данного производителя в минимальной степени подвержены изломам отдельных частей при воздействии негативных факторов условий работы спецтехники.",
      image: IMAGES.Advantages2
    },
    {
      title: "Экспорт в  другие страны",
      text: "Шины Starmaxx экспортируются в более чем сто стран мира и имеют широкую и стабильную дистрибьюторскую сеть на мировом рынке шин.",
      image: IMAGES.Advantages3
    },
    {
      title: "Уникальный подход",
      text: "Инновации, уникальный подход к качеству и лидерская миссия в сочетании с качественным и ориентированным на клиента производством позволяют бренду Starmaxx постоянно развиваться и укреплять свои позиции на международном рынке.",
      image: IMAGES.Advantages4
    }

  ];

  return (
    <PageTransition>
      <section className="about-company-page">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{props.title}</title>
        </Helmet>
        {/*<MetaTags>*/}
        {/*  <title>{props.title}</title>*/}
        {/*</MetaTags>*/}
        <Breadcrumbs/>
        <Container fluid className="about-company-page-banner">

          <picture>
            <source media="(min-width: 1920px)" srcSet={IMAGES.AboutCompanyBgBig}/>
            <img src={IMAGES.AboutCompanyBg}
                 className="main-banner__image"
                 alt="О компании"/>
          </picture>

          {/*<img src={IMAGES.AboutCompanyBg}*/}
          {/*     className="main-banner__image"*/}
          {/*     alt="О компании"/>*/}
          <Container className="about-company-page-banner__container">
            <div className="about-company-page-banner__title-block ">
              <h1 className="about-company-page-banner__title">
                о компании
              </h1>
              {/*<p className="about-company-page-banner__sub-title">*/}
              {/*  <strong>STARMAXX</strong> является торговой маркой компании <strong>PETLAS</strong> Tire Industry Co.*/}
              {/*  Ассортимент шин Starmaxx представлен*/}
              {/*  покрышками для различных категорий транспорта и специализированной техники – от легковых автомобилей до*/}
              {/*  индустриальных машин.*/}
              {/*</p>*/}
            </div>

          </Container>

        </Container>

        <Container fluid className="about-company-page-info">
          <Container className="about-company-page-info__container">
            <div className="company-product-list">
              <div className="company-product-item">
                <img
                  src={IMAGES.AboutTyre1}
                  alt="Шина для квадро/мото"
                  className="company-product-item__image-tyre"
                  style={{top: '-207px'}}
                />
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car1} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для квадро/
                  <br/>мототехники
                </p>
              </div>
              <div className="company-product-item">
                <img
                  src={IMAGES.AboutTyre2}
                  alt="Шина многоцелевого назначения"
                  className="company-product-item__image-tyre"
                  style={{top: '-210px'}}
                />

                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car2} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>многоцелевого
                  <br/>назначения
                </p>
              </div>
              <div className="company-product-item">
                <img
                  src={IMAGES.AboutTyre3}
                  alt="Шина для сельхозтехники"
                  className="company-product-item__image-tyre"
                  style={{top: '-208px'}}
                />
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car3} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для
                  <br/>сельхозтехники
                </p>
              </div>
              <div className="company-product-item">
                <img
                  src={IMAGES.AboutTyre4}
                  alt="шины для спецтехники"
                  className="company-product-item__image-tyre"
                  style={{top: '-206px'}}
                />
                <div className="company-product-item__icon-area">
                  <img src={IMAGES.Car4} alt="" className="company-product-item__icon"/>
                </div>
                <p className="company-product-item__text">
                  шины
                  <br/>для
                  <br/>спецтехники
                </p>
              </div>
              {/*<div className="company-product-item">*/}
              {/*  <div className="company-product-item__icon-area">*/}
              {/*    <img src={IMAGES.Car5} alt="" className="company-product-item__icon"/>*/}
              {/*  </div>*/}
              {/*  <p className="company-product-item__text">*/}
              {/*    шины*/}
              {/*    <br/>для*/}
              {/*    <br/>грузовиков*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </Container>
        </Container>

        <AboutBlockAboutPage />

        <Container fluid className="companies-num">
          <Container className="companies-num__container">
            <div className="title-block production__title-block">
              <h2>
                О компании в цифрах
              </h2>
            </div>

          </Container>
        </Container>
        <Container fluid className="companies-page-about-grid">
          <Container className="companies-num__container">
            <AboutGrid/>
          </Container>
        </Container>
        {/*<FormCommunicationManager/>*/}

      </section>
    </PageTransition>
  );
};

export default AboutCompanyPage;
