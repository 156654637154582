import React, {memo, useEffect, useLayoutEffect, useRef, createRef} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {createUrl} from "../../redux/actions/filters";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from 'react-paginate';
import {findDOMNode} from 'react-dom'

const Pagination = ({pagination,location}) => {

  const {total, limit, offset} = pagination

  const dispatch = useDispatch()

  const navigate = useNavigate();

  // const location = useLocation();

  const filters = useSelector((state) => state.filters);

  const products = useSelector((state) => state.products);

  const handlePageClick = (e) => {
    const _data = {
      value: e.selected * limit,
      filter_field: 'offset',
      type: 'pagination'
    }
    dispatch(createUrl(_data))
  }

  const goTo = (e) => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
    // navigate(`${location.pathname}?page=${+e.selected + 1}&limit=${limit}`)
    handlePageClick(e)
  }

  return (
    <>
      <div className="pagination-area" style={products.loadingProducts ? {pointerEvents: 'none'} : {}}>
        {filters?.pagination?.total
          ?
          <ReactPaginate
            // hrefBuilder={(page, pageCount, selected) => {
            //   return page >= 1 && page <= pageCount ? `${location.pathname}?page=${page}&limit=${limit}` : '#'
            // }}
            // hrefBuilder={(page, pageCount, selected) =>{
            //  return  page >= 1 && page <= pageCount ? `/catalog?${filters.url}` : '#'
            // }}
            // onPageActive={(e)=> console.log(e)}
            breakLabel="..."
            nextLabel="Далее"
            containerClassName="pagination"
            pageClassName="pagination__item"
            pageLinkClassName="pagination__link "
            previousClassName="pagination__button _prev"
            nextClassName="pagination__button _next"
            breakClassName="pagination__dots"
            onPageChange={(e) => goTo(e)}
            forcePage={offset / limit}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={Math.ceil(total / limit)}
            previousLabel="Назад"
            renderOnZeroPageCount={null}
            hrefAllControls
          />
          :
          ''
        }
      </div>
    </>
  );
};

export default memo(Pagination);
